<!DOCTYPE html>
<html>

<head>
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
    charset="utf-8">
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
  <!-- Include in <head> to load fonts from Google -->
  <link href='https://fonts.googleapis.com/css?family=Lato:100italic' rel='stylesheet' type='text/css'>
  <link href='https://fonts.googleapis.com/css?family=Lato:100' rel='stylesheet' type='text/css'>
  <link href='https://fonts.googleapis.com/css?family=Lato:normal' rel='stylesheet' type='text/css'>
  <link href='https://fonts.googleapis.com/css?family=Lato:300' rel='stylesheet' type='text/css'>

</head>

<body style="height: 100vh">
  <app-menu></app-menu>
  <div class="searchDiv" xLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="0px" fxLayoutGap.xs="0">
    <span fxFlex="40%" fxFlexOrder="1" class="title">Indicadores</span>
    <div fxFlex="60%" fxFlexOrder="2" style="display: flex; justify-content: flex-end; align-items: center">
      <form id="searchForm" [formGroup]="searchForm">
        <div class="container" style="margin-left: 30px;">
          <label>Reporte</label>
          <mat-form-field style="margin-left: 15px;">
            <mat-select placeholder="Indicador" #indicatorId  (selectionChange)="select($event.value)">
              <mat-option *ngFor="let indicator of indicators" [value]="indicator.url">{{indicator.title}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <label>Fecha desde</label>
          <mat-form-field class="date" style="margin-left: 15px;" [hideRequiredMarker]="false">
            <input matInput type="date" #from formControlName="from" useValueAsDate required>
          </mat-form-field>
          <label style="margin-left: 15px;margin-right: 15px;">hasta</label>
          <mat-form-field>
            <input matInput type="date" #to formControlName="to" useValueAsDate required>
          </mat-form-field>
          <button style="margin-right: 24px; margin-left: 30px" mat-raised-button class="search-button"
            (click)="search(from.value,to.value)" [disabled]="searchForm.invalid || loading">BUSCAR</button>
        </div>
        <div>
          <!-- <button style="margin-left:30px" mat-raised-button class="back-button" type="button" (click)="onBackClick()">ATRÁS</button>-->
        </div>
      </form>
    </div>
  </div>
  <div>
    <section style="margin-left: 30px; margin-right: 30px; margin-top: 30px" class="main">
        <table >
            <thead>
                <tr class="table-title" >
                    <th *ngFor="let row of rows; let indexRow = index"  scope="col" [appSort]="items" data-order="desc" [attr.data]="indexRow" >{{row}}</th>
                </tr>
            </thead>
            <tbody>
                <ng-container>
                  <tr *ngFor="let column of items | paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
                        <td *ngFor="let value of column">{{value}}</td>
                  </tr>
                </ng-container>
            </tbody>
        </table>
    </section>
    <pagination-controls  *ngIf="items.length > 0" class="my-pagination" (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
</div>



</body>

</html>
