import { Injectable, Injector } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { Reason } from './domain/Reason';
import { BaseService } from './base.service';


@Injectable()
export class ReasonService extends BaseService{

	private reasonURL = `${environment.host}/reason`;  
  
	constructor(private injector: Injector, private http: HttpClient) {
		super(injector);
	}

  getReasonList(): Observable<Reason[]> {
      return this.http.get<Reason[]>(this.reasonURL,  { headers:this.getHeaders() })
         .pipe(
         catchError(this.handleError<Reason[]>('reason list'))
       );
	 }
	 
  
}
