<!DOCTYPE html>
<html>

<head>
	<title>Recuperar contraseña</title>
	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
	<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
	<!-- Include in <head> to load fonts from Google -->
	<link href='https://fonts.googleapis.com/css?family=Lato:100italic' rel='stylesheet' type='text/css'>
	<link href='https://fonts.googleapis.com/css?family=Lato:100' rel='stylesheet' type='text/css'>
	<link href='https://fonts.googleapis.com/css?family=Lato:normal' rel='stylesheet' type='text/css'>
	<link href='https://fonts.googleapis.com/css?family=Lato:300' rel='stylesheet' type='text/css'>
</head>

<body>
	<app-menu></app-menu>
	<div fxLayout="column" fxLayoutGap="0px" fxLayoutGap.xs="10px" style="width: 100%; height: 90vh;" class="main-container">
		<div fxFlexOrder="1" fxFlex="30%">
			<legend class="title" style="margin-top:50px;">Recuperar contraseña</legend>
		</div>
		<form fxFlexOrder="2" fxFlex="60%" name="form" (ngSubmit)="f.form.valid && onSubmitClick()" #f="ngForm" novalidate>
			<div style="margin-left: 30px;">
				<mat-form-field [hideRequiredMarker]="false">
					<input matInput type="email" [(ngModel)]="user.email" name="email" #email="ngModel" placeholder="Email" id="email" required
					 email>
					<mat-error *ngIf="f.submitted && email.errors?.email && !email.errors?.required">El formato del email es incorrecto</mat-error>
					<mat-error *ngIf="f.submitted && email.errors?.required">Ingresá tu email</mat-error>
				</mat-form-field>
			</div>
			<br>
			<div>
				<button [disabled]="loading" mat-raised-button class="recover-button" style="margin-left:30px">RECUPERAR</button>
				<button style="margin-left:30px" mat-raised-button class="cancel-button" type="button" (click)="onCancelClick()">CANCELAR</button>
			</div>
			<div style="justify-content: center;width:100%;margin-top: 20px; margin-right:30px; text-align: center">
				<mat-error >{{error}}</mat-error>
			</div>	
		</form>
	</div>
	<ng-progress></ng-progress>
</body>

</html>