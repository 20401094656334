import { Injectable, Injector } from "@angular/core";
import { MedicalRecord } from "./domain/MedicalRecord";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { MedicalRecordDTO } from "./domain/MedicalRecordDTO";
import { environment } from "../environments/environment";
import { BaseService } from "./base.service";
import { ClinicHistoryDTO } from "./domain/ClinicHistoryDTO";
import { FileResponseDTO } from "./domain/FileResponseDTO";
import { Affiliate } from "./domain/Affiliate";
import { Videocall } from "./domain/Videocall";

@Injectable()
export class MedicalRecordService extends BaseService {
  private medicalRecordURL = `${environment.host}/user/`;
  private endVideoCallURL = `${environment.host}/videocall/end`;
  private medicalRecordV2URL = `${environment.host}/v2/medical-records`;
  private medicalRecordDownloadURL = `${environment.host}/v2/medical-records/download`;
  private medicalRecordV2 = `${environment.api2}/medical-record`;
  private medicalRecordSendEmail = `${environment.api2}/send-data-to-email`;

  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  getMedicalRecordList(userId: number): Observable<MedicalRecord[]> {
    return this.http
      .get<MedicalRecord[]>(`${this.medicalRecordURL}${userId}/medicalrecord`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError("getMedicalRecordList", [])));
  }

  getMedicalRecordById(id: number): Observable<MedicalRecord> {
    const url = `${this.medicalRecordURL}/${id}`;
    return this.http
      .get<MedicalRecord>(url, { headers: this.getHeaders() })
      .pipe(
        catchError(
          this.handleError<MedicalRecord>(`getMedicalRecordById id=${id}`)
        )
      );
  }

  saveMedicalRecord(
    medicalRecord: MedicalRecordDTO
  ): Observable<MedicalRecord> {
    return this.http
      .post<MedicalRecord>(this.endVideoCallURL, medicalRecord, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError<MedicalRecord>("saveMedicalRecord")));
  }

  
  saveMedicalRecordV2(
    medicalRecord: MedicalRecordDTO
  ): any {
    return this.http
      .post<any>(this.medicalRecordV2, medicalRecord, {
        headers: this.getHeaders(),
      })
  }

  getMedicalRecordListByFilters(
    params: String
  ): Observable<ClinicHistoryDTO[]> {
    return this.http
      .get<ClinicHistoryDTO[]>(this.medicalRecordV2URL + params, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError("getMedicalRecordListByFilters", [])));
  }

  getFileInBase64ToDownload(params: String): Observable<FileResponseDTO> {
    return this.http
      .get<FileResponseDTO>(`${this.medicalRecordDownloadURL}${params}`, {
        headers: this.getHeaders(),
      })
      .pipe(
        catchError(
          this.handleError<FileResponseDTO>("getFileInBase64ToDownload")
        )
      );
  }

  sendMedicalRecordEmail(patient: string, videocall: number): Observable<any> {
    return this.http.post<any>(
      this.medicalRecordSendEmail,
      { patient, id_videocall: videocall }
    );
  }
}
