import { Router } from "@angular/router";
import { Observable ,  of } from "rxjs";
import { Session } from "./util/Session";
import { Injector } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";

export class BaseService {

	private router: Router;

	constructor(injector: Injector) {
		this.router = injector.get(Router);
	}

	getHeaders(): HttpHeaders {
		return new HttpHeaders({ 'Content-Type': 'application/json' })
			.set("Authorization", localStorage.getItem('currentUser'));
	}

	getHeadersWithoutContentType(): HttpHeaders {
		return new HttpHeaders()
			.set("Authorization", localStorage.getItem('currentUser'));
	}

   /**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	handleError<T> (operation = 'operation', result?: T) {
	  return (error: any): Observable<T> => {
			console.log(`${operation} failed:,`, error);
			console.log(`Error status: ${error.status}`);
			if(error.status == 408 || error.status == 401 || error.status == 0){
				Session.sessionExpired();
				this.router.navigate(['/login']);
			}
	    // Let the app keep running by returning an empty result.
	    return of(result as T);
	  };
	}

}
