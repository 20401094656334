import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
  TemplateRef,
} from "@angular/core";
import { MedicalRecord } from "../domain/MedicalRecord";
import { MedicalRecordService } from "../medical-record.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { VideocallService } from "../videocall.service";
import { Videocall } from "../domain/Videocall";
import { UserService } from "../user.service";
import { Affiliate } from "../domain/Affiliate";
import { MedicalRecordDTO } from "../domain/MedicalRecordDTO";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { ChangeDetectorRef } from "@angular/core";
import { NgForm, FormControl } from "@angular/forms";
import { EventEmitter, Input, Output } from "@angular/core";
import { interval, Subscription } from "rxjs";
import { ModalService } from "./modal.service";
import { Reason } from "../domain/Reason";
import { ReasonService } from "../reason.service";
import { Session } from "../util/Session";
import { COMMA, ENTER, TAB } from "@angular/cdk/keycodes";
import { startWith, map } from "rxjs/operators";
import { StringUtil } from "../util/StringUtil";
import { MatDialogConfig } from "@angular/material/dialog";
import { PopupPrescriptionComponent } from "../popup-prescription/popup-prescription.component";
import { isUndefined } from "util";
import { PrescriptionService } from "../prescription.service";
var Video = require("twilio-video");
const { connect, createLocalTracks } = require("twilio-video");

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  //Constants
  BUTTON_TEXT_NEW: string = "NUEVO PACIENTE";
  BUTTON_TEXT_MAKE_CALL: string = "LLAMAR";
  BUTTON_TEXT_RETRY: string = "REINTENTAR";

  // Current Call variables
  callInProgress: boolean = false;
  // Current VideoCall variables
  currentVideocall: Videocall;
  currentVideocallAffiliates: Affiliate[];
  currentPatient: Affiliate[];
  //interviewList: Interview[];
  //selectedInterviewList: String[] = new Array();
  //filteredInterviewList: Observable<any[]>;
  reasonList: Reason[];
  medicalRecordList: MedicalRecord[];
  visibleMedicalRecordList: MedicalRecord[];
  selectedMedicalRecord: MedicalRecord;
  newMedicalRecord: MedicalRecordDTO = new MedicalRecordDTO();
  // View state variales
  loading: boolean = false;
  hideSend: boolean = true;
  hidePrescription: boolean = true;
  disableCallButton: boolean = false;
  noMediaDevices: boolean = false;
  emptyNextVideocall: boolean = false;
  buttonText: string = this.BUTTON_TEXT_NEW;
  retryNumbers: number = 0;
  noQueue: boolean = true;
  subscription: Subscription = null;
  isAdmin: boolean = false;
  username: String;
  isMute: Boolean = false;
  queueCount: number;
  errorQueue: Boolean = false;
  videocallAsync: any;
  isMedicalRecordListLoading = false;
  isFullscreen = false;
  containerZindex: number = 12;
  homePath: string;

  constructor(
    private router: Router,
    private videocallService: VideocallService,
    private medicalRecordService: MedicalRecordService,
    private userService: UserService,
    private reasonService: ReasonService,
    private prescriptionService: PrescriptionService,
    private location: Location,
    private ref: ChangeDetectorRef,
    public modalService: ModalService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.homePath = Session.getHomePath();
    console.log(this.homePath);
    console.log(this.hidePrescription);
    this.reasonService.getReasonList().subscribe((reasonList) => {
      this.reasonList = reasonList;
    });
    this.getCurrentVideocall();
    this.getCurrentQueueStatus();
    //this.getInterviewList();
    this.username = localStorage.getItem("currentUserName");
    this.isAdmin = Session.isAdmin();
    if (localStorage.getItem("isWebRTCSupported") == "false") {
      this.handleMediaError(
        "Doctor en Casa",
        "Tu navegador no soporta videollamadas.\nPuedes utilizar las otras funciones de la web.",
        "ACEPTAR"
      );
    }
  }

  ngAfterViewInit() {
    let loadingElement = document.querySelector("#loading");
    if (loadingElement) {
      loadingElement.remove();
    }
  }

  ngOnDestroy() {
    this.ref.detach();
    this.leaveRoomIfJoined();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.expirationSubscription) {
      this.expirationSubscription.unsubscribe();
    }
  }

  // GET INFO
  getCurrentQueueStatus() {
    this.videocallService.queue().subscribe((queue) => {
      this.emptyNextVideocall = this.noQueue = queue == 0;
      this.errorQueue = queue == null;
      this.queueCount = queue;
      this.retryGetCall();
      this.detectChanges();
    });
  }

  getCurrentVideocall() {
    this.videocallService.current().subscribe((current) => {
      this.disableCallButton = false;
      if (current) {
        this.currentVideocall = current;
        if (
          this.currentVideocall != null &&
          this.currentVideocall.affiliate != null
        ) {
          if (
            this.currentVideocall.status == "LISTA_ATENCION" ||
            this.currentVideocall.status == "EN_PROGRESO"
          ) {
            this.buttonText = this.BUTTON_TEXT_MAKE_CALL;
            console.log("1");
            this.getAffiliates();
          } else if (this.currentVideocall.status == "FINALIZADA") {
            this.currentCallFinish();
          }
        }
        this.detectChanges();
      }
    });
  }

  /*getAffiliateList() {
    this.userService.getAffiliateListByGroup(this.currentVideocall.affiliate.groupNumber,
      this.currentVideocall.affiliate.provider.id)
      .subscribe(affiliates => {
        if (affiliates) {
          this.currentVideocallAffiliates = affiliates;
          if (affiliates && affiliates.length > 0) {
            this.newMedicalRecord.affiliateGamId = affiliates[0].affiliateGamId;
          }
          this.getClinicHistoryList(this.currentVideocall.affiliate.id);
        } else {
          this.openDialog("Error", "No fue posible obtener la lista de pacientes. \nPor favor, volvé a cargar la página ", "ACEPTAR");
        }
      });
  }*/

  getAffiliates() {
    this.userService
      .getAffiliates(this.currentVideocall.affiliate.id)
      .subscribe((affiliates) => {
        if (affiliates) {
          this.currentVideocallAffiliates = affiliates;
          if (affiliates && affiliates.length > 0) {
            if (affiliates[0].affiliateGamId) {
              console.log("Affiliats" + affiliates);
              this.newMedicalRecord.affiliateGamId =
                affiliates[0].affiliateGamId;
              this.newMedicalRecord.patient = affiliates[0].patient;
              console.log("newMedicalRecord" + this.newMedicalRecord);
            } else {
              console.log("--PATIENT--");
              console.log("PATIENT:" + affiliates[0].patient);
              this.newMedicalRecord.patient = affiliates[0].patient;
            }
          }
          this.getClinicHistoryList(this.currentVideocall.affiliate.id);
        } else {
          this.openDialog(
            "Error",
            "No fue posible obtener la lista de pacientes. \nPor favor, volvé a cargar la página ",
            "ACEPTAR"
          );
        }
      });
  }

  getClinicHistoryList(userId: number): void {
    this.isMedicalRecordListLoading = true;
    this.medicalRecordService
      .getMedicalRecordList(userId)
      .subscribe((medicalRecordList) => {
        this.medicalRecordList = medicalRecordList;
        this.visibleMedicalRecordList = medicalRecordList;
        console.log("getClinicHistoryList");
        console.log(medicalRecordList);
        console.log(this.visibleMedicalRecordList);
        if (this.newMedicalRecord.affiliateGamId) {
          console.log("affiliateID" + this.newMedicalRecord.affiliateGamId);
          this.onAffiliateSelected();
        }
        this.isMedicalRecordListLoading = false;
      });
  }

  /*getInterviewList(): void {
    this.interviewService.getList().subscribe(interviewList => {
      this.interviewList = interviewList;
      this.filteredInterviewList = this.interviewCtrl.valueChanges.pipe(
        startWith(null),
        map((interview: string | null) => interview ? this.filter(interview) : this.interviewList.slice()));
    });
  }*/

  currentCallFinish() {
    this.getAffiliates();
    this.hideSend = false;
    this.disableCallButton = true;
    this.detectChanges();
  }

  startExpirationCounter() {
    if (this.expirationSubscription) {
      this.expirationSubscription.unsubscribe();
    }
    this.expirationSubscription = interval(30000).subscribe((x) => {
      if (
        this.callInProgress &&
        this.activeRoom &&
        document.getElementsByTagName("video")[0] == null
      ) {
        this.videocallTimeExpired = true;
        this.activeRoom.disconnect();
      }
    });
  }

  onCallEstablished(): void {
    this.retryNumbers = 0;
    this.callInProgress = true;
    this.detectChanges();
    this.startExpirationCounter();
    this.videocallService
      .call(this.currentVideocall.id)
      .subscribe((videocall) => {});
  }

  onCallEnded(): void {
    this.userConnected = false;
    this.trackDisabled = false;
    this.retryNumbers = 0;
    this.callInProgress = false;
    this.isMute = false;
    this.activeRoom = null;
    this.removeVideo();
    this.disableCallButton = true;
    this.buttonText = this.BUTTON_TEXT_NEW;
    if (this.videocallTimeExpired) {
      this.videocallService
        .missed(this.currentVideocall.id)
        .subscribe((videocall) => {
          this.resetForm();
          this.detectChanges();
          this.noResponseDialog();
        });
      this.videocallTimeExpired = false;
    } else {
      this.videocallService
        .finish(this.currentVideocall.id)
        .subscribe((videocall) => {
          this.retryDialog();
        });
    }
    this.detectChanges();
  }

  retryCall(): void {
    this.buttonText = this.BUTTON_TEXT_MAKE_CALL;
    this.disableCallButton = false;
  }

  doCall(): void {
    if (this.currentVideocall == null) {
      this.nextCall();
    } else {
      if (this.activeRoom == null) {
        this.makeCall();
      } else {
        this.hangout();
        this.buttonText = this.BUTTON_TEXT_NEW;
      }
    }
  }

  hangout(): void {
    if (this.activeRoom) {
      // To disconnect from a Room
      this.activeRoom.disconnect();
    }
  }

  mute(): void {
    if (this.activeRoom && this.localAudioTrack) {
      this.localAudioTrack.enable(this.isMute);
      this.isMute = !this.isMute;
      this.detectChanges();
    }
  }

  fullscreen(): void {
    var video = document.getElementsByTagName("video")[0];
    debugger;
    if (video) {
      video.setAttribute(
        "style",
        "z-index:1000;object-fit:fill;width:100vw;height:100vh; top: 0; left: 0; position: fixed"
      );
      video.remove;
    }
    this.isFullscreen = true;
  }

  onVideoClick() {
    if (this.isFullscreen) {
      this.setVideoSize();
      this.isFullscreen = false;
    }
  }

  onMedicalRecordClick(medicalRecord: MedicalRecord): void {
    this.medicalRecordService
      .getMedicalRecordById(medicalRecord.id)
      .subscribe(
        (selectedMedicalRecord) =>
          (this.selectedMedicalRecord = selectedMedicalRecord)
      );
  }

  dismissCall(): void {
    this.videocallService
      .dismissed(this.currentVideocall.id)
      .subscribe((videocall) => {
        this.resetForm();
        this.detectChanges();
      });
  }

  nextCall(): void {
    this.emptyNextVideocall = false;
    this.videocallService.nextCall().subscribe((videocall) => {
      this.noQueue = false;
      this.currentVideocall = videocall;
      if (
        this.currentVideocall != null &&
        this.currentVideocall.affiliate != null
      ) {
        this.buttonText = this.BUTTON_TEXT_MAKE_CALL;
        this.getAffiliates();
      } else {
        this.emptyNextVideocall = true;
        this.noQueue = true;
      }
    });
  }

  retryGetCall(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = interval(10000).subscribe((x) => {
      this.videocallService.queue().subscribe((queue) => {
        this.queueCount = queue;
        if (queue == 0) {
          this.noQueue = true;
        } else {
          this.noQueue = false;
        }
        this.detectChanges();
      });
    });
  }

  // FORM
  onAffiliateSelected(): void {
    this.visibleMedicalRecordList = this.medicalRecordList.filter(
      (medicalRecord: MedicalRecord) =>
        medicalRecord.affiliateGamId === this.newMedicalRecord.affiliateGamId
    );
    this.currentPatient = this.currentVideocallAffiliates.filter(
      (affiliate: Affiliate) =>
        affiliate.affiliateGamId === this.newMedicalRecord.affiliateGamId
    );
    this.newMedicalRecord.patient = this.currentPatient[0].patient;
  }

  onSubmitClick(myForm: NgForm): void {
    this.newMedicalRecord.videocallId = this.currentVideocall.id;
    if (!this.newMedicalRecord.recommendation) {
      return;
    }
    if (!this.newMedicalRecord.anamnesis) {
      return;
    }
    if (!this.newMedicalRecord.reason) {
      return;
    }
    if (this.newMedicalRecord.reason.length === 0) {
      return;
    }

    this.loading = true;
    this.medicalRecordService
      .saveMedicalRecordV2(this.newMedicalRecord)
      .subscribe((medicalRecord) => {
        this.loading = false;
        if (medicalRecord?.data) {
          this.resetForm();
          myForm.resetForm();
          this.getCurrentQueueStatus();
          try {
            this.medicalRecordService
              .sendMedicalRecordEmail(medicalRecord?.data?.patient, medicalRecord?.data?.videocall.id)
              .subscribe(
                (emailResponse) => {
                  console.log("emailResponse", emailResponse);
                  const success = emailResponse?.success;
                  if (success) {
                    this.openDialog(
                      "Comprobante de consulta",
                      "Se envió el comprobante de consulta con éxito",
                      "ACEPTAR"
                    );
                  } else {
                    this.openDialog(
                      "Comprobante de consulta",
                      "No fue posible enviar el comprobante de la consulta",
                      "ACEPTAR"
                    );
                  }
                },
              );
          } catch (error) {
            console.log("there was an error sending the email");
            this.openDialog(
              "Comprobante de consulta",
              "No fue posible enviar el comprobante de la consulta",
              "ACEPTAR"
            );
          }
          this.openDialog(
            "Llamada finalizada",
            "Los datos fueron guardados correctamente",
            "ACEPTAR"
          );
        } else {
          this.openDialog(
            "Error",
            "Hubo un error guardando los datos de la consulta. \nPor favor, volvé a intentarlo ",
            "ACEPTAR"
          );
        }
      });
  }

  onSubmitWithoutVideocallClick(myForm: NgForm): void {
    this.newMedicalRecord.videocallId = this.currentVideocall.id;
    this.loading = true;
    this.videocallService
      .finishWithoutVideocall(this.newMedicalRecord.videocallId)
      .subscribe((videocall) => {
        this.loading = false;
        if (videocall) {
          this.resetForm();
          myForm.resetForm();
          this.getCurrentQueueStatus();
          this.openDialog(
            "Consulta finalizada",
            "La consulta fue actualizada correctamente",
            "ACEPTAR"
          );
        } else {
          this.openDialog(
            "Error",
            "Hubo un error guardando los datos de la consulta. \nPor favor, volvé a intentarlo ",
            "ACEPTAR"
          );
        }
      });
  }

  resetForm() {
    this.visibleMedicalRecordList = null;
    this.isMedicalRecordListLoading = false;
    this.medicalRecordList = null;
    this.currentVideocallAffiliates = null;
    this.hideSend = true;
    this.hidePrescription = true;
    this.disableCallButton = false;
    this.currentVideocall = null;
    this.currentPatient = null;
    this.buttonText = this.BUTTON_TEXT_NEW;
    this.newMedicalRecord = new MedicalRecordDTO();

    //this.interviewInput.nativeElement.value = '';

    this.noQueue = true;
    this.subscription = null;
    this.errorQueue = false;
    this.getCurrentVideocall();
    this.getCurrentQueueStatus();
    this.detectChanges();
  }

  //END FORM

  // VIDEOCALL
  activeRoom: any;
  localAudioTrack: any;
  localVideoTrack: any;
  localTracks: any;
  videocallTimeExpired: boolean = false;
  expirationSubscription: Subscription = null;
  connectingToRoom: boolean = false;
  userConnected: boolean = false;
  trackDisabled: boolean = false;
  //Constants
  PARTICIPANT_IDENTITY: string = "ROLE_USER";

  makeCall(): void {
    createLocalTracks({
      audio: true,
      video: {
        width: 1024,
      },
    }).then(
      (localTracks) => {
        this.localTracks = localTracks;
        // Attach the Tracks of the Room's Participants.
        localTracks.forEach((track) => {
          if (track.kind == "audio") {
            this.localAudioTrack = track;
          }
          if (track.kind == "video") {
            this.localVideoTrack = track;
          }
        });

        var connectOptions = {
          name: this.currentVideocall.roomName,
          tracks: localTracks,
        };
        this.connectingToRoom = true;
        Video.connect(this.currentVideocall.tokenTwilio, connectOptions).then(
          (x) => {
            this.roomJoined(x);
          },
          (error) => {
            console.log("Could not connect to Twilio: " + error.message);
            this.connectingToRoom = false;
            if (this.retryNumbers < 3) {
              this.retryNumbers = this.retryNumbers + 1;
              this.retryCall();
            }
          }
        );
      },
      (error) => {
        this.handleMediaError(
          "Ups!",
          "No fue posible encontrar una cámara web.\nPor favor, volvé a cargar la página o intentá reiniciando la computadora",
          "ACEPTAR"
        );
      }
    );
  }

  handleMediaError(title: string, message: string, buttonLabel: string) {
    this.disableCallButton = true;
    this.noMediaDevices = true;
    this.detectChanges();
    this.openDialog(title, message, buttonLabel);
  }

  // Successfully connected!
  roomJoined(room) {
    this.activeRoom = room;
    this.connectingToRoom = false;
    this.hidePrescription = false;

    // Attach the Participant's Media to a <div> element.
    room.on("participantConnected", (participant) => {
      console.log(`Participant "${participant.identity}" connected`);

      if (participant.identity == this.PARTICIPANT_IDENTITY) {
        if (this.expirationSubscription) {
          this.expirationSubscription.unsubscribe();
        }
        this.userConnected = true;
        this.videocallService
          .start(this.currentVideocall.id, this.activeRoom.sid)
          .subscribe((videocall) => {});

        participant.tracks.forEach((publication) => {
          if (publication.isSubscribed) {
            const track = publication.track;
            document.getElementById("video").appendChild(track.attach());
          }

          publication.on("trackDisabled", () => {
            if (publication.track) {
              console.log(publication.track);
              if (publication.track.kind === "video") {
                this.trackDisabled = true;
              }
            }
          });

          publication.on("trackEnabled", () => {
            if (publication.track) {
              console.log(publication.track);
              if (publication.track.kind === "video") {
                this.trackDisabled = false;
              }
            }
          });
        });

        participant.on("trackSubscribed", (track) => {
          document.getElementById("video").appendChild(track.attach());
          this.setVideoSize();
        });

        participant.on("trackUnsubscribed", (track) => {
          track.detach().forEach((element) => element.remove());
        });
      }
    });

    // When a Participant leaves the Room, detach its Tracks.
    room.on("participantDisconnected", (participant) => {
      if (participant.identity == this.PARTICIPANT_IDENTITY) {
        console.log(`Participant disconnected: ${participant.identity}`);
        room.localParticipant.tracks.forEach((publication) => {
          const attachedElements = publication.track.detach();
          attachedElements.forEach((element) => element.remove());
        });
        this.activeRoom.disconnect();
      }
    });

    // Once the LocalParticipant leaves the room, detach the Tracks
    // of all Participants, including that of the LocalParticipant.
    room.on("disconnected", (room) => {
      console.log("disconnected");
      room.localParticipant.tracks.forEach((publication) => {
        const attachedElements = publication.track.detach();
        attachedElements.forEach((element) => element.remove());
        //turn off camera
        publication.track.stop();
      });
      this.onCallEnded();
    });
    this.onCallEstablished();
  }

  // Leave Room.
  leaveRoomIfJoined() {
    if (this.activeRoom) {
      this.activeRoom.disconnect();
    }
  }

  setVideoSize() {
    var video = document.getElementsByTagName("video")[0];
    var container = document.getElementById("main-right");
    var parent = document.getElementById("videocall-container");
    if (video && container) {
      video.height = container.offsetHeight;
      video.width = parent.offsetWidth;
      video.setAttribute("style", "object-fit:fill");
      video.addEventListener("click", this.onVideoClick.bind(this));
    }
  }

  removeVideo() {
    var previewContainer = document.getElementById("video");
    var video = document.getElementsByTagName("video")[0];
    var audio = document.getElementsByTagName("audio")[0];
    if (video) {
      previewContainer.removeChild(video);
    }
    if (audio) {
      previewContainer.removeChild(audio);
    }
  }
  //END VIDEOCALL

  // UTILS
  detectChanges() {
    if (!this.ref["destroyed"]) {
      this.ref.detectChanges();
    }
  }

  splitReasons(reasons: Reason[]): string {
    return StringUtil.splitReasons(reasons);
  }

  toDate(date: number): string {
    return StringUtil.toDate(date);
  }
  // END UTILS

  // DIALOGS
  // Dialog variables
  commonDialogId = "commonDialogId";
  retryDialogId = "retryDialogId";
  noResponseDialogId = "noResponseDialogId";
  dialogTitle: String;
  dialogText: String;
  dialogButtonText: String;
  //@ViewChild('interviewInput') interviewInput: ElementRef;

  noResponseDialog(): void {
    let modal = this.modalService.open(this.noResponseDialogId);
    this.detectChanges();
    modal.finalizeClick.subscribe((data) => {
      this.getCurrentQueueStatus();
      this.detectChanges();
    });
  }

  openDialog(title: string, message: string, buttonLabel: string): void {
    this.dialogButtonText = buttonLabel;
    this.dialogText = message;
    this.dialogTitle = title;
    let modal = this.modalService.open(this.commonDialogId);
    this.detectChanges();
    modal.finalizeClick.subscribe((data) => {
      this.detectChanges();
    });
  }

  retryDialog(): void {
    let modal = this.modalService.open(this.retryDialogId);
    modal.finalizeClick.subscribe((data) => {
      if (data == 1) {
        this.retryCall();
        this.detectChanges();
      } else {
        this.videocallService
          .finish(this.currentVideocall.id)
          .subscribe((videocall) => {
            this.hideSend = false;
            this.disableCallButton = true;
            this.getCurrentQueueStatus();
            this.detectChanges();
          });
      }
    });
    this.detectChanges();
  }
  //END DIALOG
  /*
  // CHIPS
  interviewCtrl = new FormControl();
  separatorKeysCodes = [ENTER, COMMA, TAB];

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.selectedInterviewList.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.interviewCtrl.setValue(null);
  }

  remove(fruit: any): void {
    const index = this.selectedInterviewList.indexOf(fruit);

    if (index >= 0) {
      this.selectedInterviewList.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedInterviewList.push(event.option.viewValue);
    this.interviewInput.nativeElement.value = '';
    this.interviewCtrl.setValue(null);
  }


  filter(name: string) {
    return this.interviewList.filter(interview =>
      interview.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  //END CHIPS
*/
  openDialogReceta() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: this.currentVideocall.id,
    };

    const dialogRef = this.dialog.open(
      PopupPrescriptionComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe(
      (data) => {
        if (!isUndefined(data)) {
          console.log(this.currentVideocall.affiliate.id);
          data.doctorId = this.currentVideocall.doctor.id;
          data.affiliateId = this.currentVideocall.affiliate.id;
          this.prescriptionService.save(data).subscribe((generalResponse) => {
            if (generalResponse) {
              console.log("Se guardo");
              console.log(data);
            } else {
              console.log("Error al guardar");
            }
          });
        }
        //} else {
        // console.log("Error1");
        // }
      },
      (error) => console.log("Error2")
    );
  }
}
