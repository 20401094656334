import { Component, OnInit } from '@angular/core';
import { User } from '../domain/User';
import { UserService } from '../user.service';
import { GeneralResponse } from '../domain/GeneralResponse';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  ngOnInit(): void {
  }

  user: User = new User();
  loading = false;
  error: string;

  constructor(private userService: UserService, private location: Location) { }

  ngAfterViewInit() {
    let loadingElement = document.querySelector('#loading');
    if(loadingElement){
      loadingElement.remove();
    }
  }

  onSubmitClick(): void {
    this.loading = true;
    this.userService.resetPassword(this.user)
      .subscribe(generalResponse =>
        this.validateResponse(generalResponse)
      );
  }

  validateResponse(generalResponse: any): void {
    if (generalResponse) {
      this.location.back();
    } else {
      this.error = 'El mail ingresado no está asociado a ningún usuario.';
      this.loading = false;
    }
  }

  onCancelClick(): void {
    this.location.back();
  }

}
