<!DOCTYPE html>
<html>

<head>
	<title>Actualizar contraseña</title>
	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
	<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
	<!-- Include in <head> to load fonts from Google -->
	<link href='https://fonts.googleapis.com/css?family=Lato:100italic' rel='stylesheet' type='text/css'>
	<link href='https://fonts.googleapis.com/css?family=Lato:100' rel='stylesheet' type='text/css'>
	<link href='https://fonts.googleapis.com/css?family=Lato:normal' rel='stylesheet' type='text/css'>
	<link href='https://fonts.googleapis.com/css?family=Lato:300' rel='stylesheet' type='text/css'>
</head>

<body>
	<app-menu></app-menu>
	<div fxLayout="column" fxLayoutGap="0px" fxLayoutGap.xs="10px" style="width: 100%; height: 90vh;" class="main-container">
		<div fxFlexOrder="1" fxFlex="20%">
			<legend class="title" style="margin-top:50px;">Cambiar contraseña</legend>
		</div>
	<form fxFlexOrder="2" fxFlex="70%" name="form" (ngSubmit)="f.form.valid && onSubmitClick()" #f="ngForm" novalidate>

		<mat-form-field [hideRequiredMarker]="false" style="margin-left: 30px; margin-top: 30px;">
			<input matInput type="password" minlength="6" [(ngModel)]="user.password" name="password" #password="ngModel" placeholder="Contraseña Anterior"
			 id="password" required>
			<mat-error *ngIf="f.submitted && password.errors?.minlength && !password.errors?.required">La contraseña debe tener al menos 6 caracteres</mat-error>
			<mat-error *ngIf="f.submitted && password.errors?.required">Ingresá la contraseña anterior</mat-error>
		</mat-form-field>
		<br>
		<mat-form-field [hideRequiredMarker]="false" style="margin-left: 30px; margin-top: 30px; ">
			<input matInput type="password" minlength="6" [(ngModel)]="user.newPassword" name="newPassword" #newPassword="ngModel" placeholder="Contraseña Nueva"
			 id="newPassword" required>
			<mat-error *ngIf="f.submitted && newPassword.errors?.minlength && !newPassword.errors?.required">La contraseña debe tener al menos 6 caracteres</mat-error>
			<mat-error *ngIf="f.submitted && newPassword.errors?.required">Ingresá la nueva contraseña</mat-error>
		</mat-form-field>
		<br>
		<mat-form-field [hideRequiredMarker]="false" style="margin-left: 30px; margin-top: 30px;">
			<input matInput type="password" minlength="6" [(ngModel)]="confirmPasswordVar" name="passwordConfirm" #passwordConfirm="ngModel"
			 placeholder="Repetir Contraseña Nueva" id="passwordConfirm" required>
			<mat-error *ngIf="f.submitted && passwordConfirm.errors?.minlength && !passwordConfirm.errors?.required">La contraseña debe tener al menos 6 caracteres</mat-error>
			<mat-error *ngIf="f.submitted && passwordConfirm.errors?.required">Repetí la nueva contraseña</mat-error>
		</mat-form-field>
		<div style="margin-left: 30px; margin-top: 30px;">
			<button [disabled]="loading" mat-raised-button class="update-button" style="margin-left:30px">ACTUALIZAR</button>
			<button   (click)="onCancelClick()" mat-raised-button class="cancel-button" type="button">CANCELAR</button>
		</div>
	</form>
	<div fxFlex="10%" fxFlexOrder="3" style="margin-top: 20px">
		<mat-error>{{error}}</mat-error>
	</div>
</div>
<ng-progress></ng-progress>
</body>

</html>