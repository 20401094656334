import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Speciality } from './domain/Speciality';
import { BaseService } from './base.service';

@Injectable()
export class SpecialityService extends BaseService{

	private specialityURL = `${environment.host}/speciality`;  
  
	constructor(private injector: Injector, private http: HttpClient) {
		super(injector);
	}

  getSpecialityList(): Observable<Speciality[]> {
      return this.http.get<Speciality[]>(this.specialityURL,  { headers:this.getHeaders() })
         .pipe(
         catchError(this.handleError<Speciality[]>('Speciality list'))
       );
	 }
	 
  
}
