export class Agreement {
  id: number;
  name: string;
  telephone: string;
  enabled: boolean;
  lastUpdate: number;
  records: number;
  website: string;
  sifeme: number;
  primaryColor: string;
    secondaryColor: string;
    mainFontColor: string;
    secondaryFontColor: string;
    font: string;
}
