<!DOCTYPE html>
<html>

<head>
	<title>Affiliados</title>
	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
	<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
	<!-- Include in <head> to load fonts from Google -->
	<link href='https://fonts.googleapis.com/css?family=Lato:100italic' rel='stylesheet' type='text/css'>
	<link href='https://fonts.googleapis.com/css?family=Lato:100' rel='stylesheet' type='text/css'>
	<link href='https://fonts.googleapis.com/css?family=Lato:normal' rel='stylesheet' type='text/css'>
	<link href='https://fonts.googleapis.com/css?family=Lato:300' rel='stylesheet' type='text/css'>
</head>

<body style="height: 100%; padding-right: 20px; width: 100vw">
	<app-menu></app-menu>


	<div class="searchDiv" xLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="0px" fxLayoutGap.xs="0">
		<div fxFlex="100%" fxFlexOrder="2" style="display: flex; justify-content: flex-end; align-items: center">
			<input style="float: right; margin-right: 20px;" class="searchInput" placeholder="Número de documento.."  type="number" name="documentNumber" #searchInput>
			<button [disabled]="loading" mat-raised-button class="button" (click)="search(searchInput.value)" style="float: right; margin-right: 5px;" >BUSCAR</button>
			<button [disabled]="loading" mat-raised-button class="button" (click)="newUser()">NUEVO</button>
		</div>
	</div>


	<div fxLayout="column" fxLayoutGap="0px" fxLayoutGap.xs="10px" style="width: 100%; height: 90vh;" class="main-container">
		<div fxFlexOrder="1" fxFlex="20%">
			<legend class="title" style="margin-top:50px;">Paciente</legend>
		</div>

		<div fxFlexOrder="2" fxFlex="70%" class="container" style="margin-left: 30px; margin-top: 30px;">

			<form name="form" (ngSubmit)="f.form.valid && onSubmitClick()" #f="ngForm" novalidate>
				<div style="text-align: center">

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput [(ngModel)]="affiliate.firstname" name="firstname" placeholder="Nombre" id="firstname" required>
						<mat-error>Ingresá nombre</mat-error>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput [(ngModel)]="affiliate.lastname" name="lastname" placeholder="Apellido" id="lastname" required>
						<mat-error>Ingresá apellido</mat-error>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput type="email" [(ngModel)]="affiliate.email" name="email" #email="ngModel" placeholder="Email" id="email"
						 required email>
						<mat-error *ngIf="f.submitted && email.errors?.email && !email.errors?.required">El formato del email es
							incorrecto</mat-error>
						<mat-error *ngIf="f.submitted && email.errors?.required">Ingresá tu email</mat-error>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput type="number" [(ngModel)]="affiliate.documentNumber" name="documentNumber" placeholder="N° Documento"
						 id="documentNumber" required>
						 <mat-error>Ingresá número de documento</mat-error>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput type="number" [(ngModel)]="affiliate.telephone" name="telephone" placeholder="Teléfono" id="telephone" required>
						<mat-error>Ingresá Teléfono</mat-error>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput type="number" [(ngModel)]="affiliate.availableCalls" name="availableCalls" placeholder="Llamadas Disponibles" id="availableCalls" required>
						<mat-error>Ingresá cantidad de llamadas</mat-error>
					</mat-form-field>

					<mat-form-field>
						<mat-select
						  placeholder="Convenios"
						  required
						  [(ngModel)]="agreementId"
						  (selectionChange)="selectAgreement($event.value)"
						  name="agreementId"
						>
						  <mat-option
							*ngFor="let agreement of agreements"
							[value]="agreement.id"
							>{{ agreement.name }}</mat-option
						  >
						</mat-select>
					  </mat-form-field>
					
					<mat-checkbox class="enable" [(ngModel)]="affiliate.enabled" name="enabled"> Habilitado </mat-checkbox>

					<mat-checkbox class="sendEmail" [(ngModel)]="affiliate.resendEmail" name="resendEmail"> Reenviar email </mat-checkbox>

				</div>
				<br>
				<div style="margin-top:30px; text-align: center">
					<button [disabled]="loading" *ngIf="this.isNewUser" mat-raised-button class="submit-button" style="margin-left:30px;">GUARDAR</button>
					<button [disabled]="loading" *ngIf="!this.isNewUser" mat-raised-button class="submit-button" style="margin-left:30px;">ACTUALIZAR</button>
					<button style="margin-left:30px" mat-raised-button class="cancel-button" type="button" (click)="onCancelClick()">CANCELAR</button>
				</div>
			</form>

		</div>

		<div fxFlexOrder="2" style="margin-top: 5px">
			<mat-error>{{error}}</mat-error>
		</div>

	</div>
	<ng-progress></ng-progress>


	<app-modal [useTwoButtons]='false' [modalTitle]="dialogTitle" [buttonTitle]="dialogButtonText" [blocking]='false'
	 [modalId]='commonDialogId'>
		<div>{{dialogText}}</div>
	</app-modal>
</body>

</html>
