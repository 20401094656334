import { Reason } from "../domain/Reason";

export class StringUtil {

  public static splitReasons(reasons: Reason[]): string {
    return reasons.map(item => item.name).join(", ");
  }

  public static toDate(i: number): string {
    var date = new Date();
    date.setTime(i);
    return date.toLocaleString();
  }
}  