import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { BaseService } from './base.service';
import { IndicatorData } from './domain/IndicatorData';
import { Videocall } from './domain/Videocall';
import { Indicator } from './domain/Indicator';

@Injectable()
export class IndicatorService extends BaseService{

  private indicatorURL = `${environment.host}/indicator`;

  constructor(private injector: Injector, private http: HttpClient) {
		super(injector);
	}

  listAllIndicators(): Observable<Indicator[]> {
		return this.http.get<Indicator[]>(this.indicatorURL, { headers: this.getHeaders() })
			.pipe(
			catchError(this.handleError<Indicator[]>('getAll indicators'))
			);
  }
  
  getIndicator(url:string, from:string, to:string): Observable<IndicatorData> {
		return this.http.get<IndicatorData>(this.indicatorURL+"/"+url+"?from="+from+"&to="+to, { headers: this.getHeaders() })
			.pipe(
			catchError(this.handleError<IndicatorData>((this.indicatorURL+"/"+url)))
			);
  }

}
