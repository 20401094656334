import { Component, OnInit } from '@angular/core';
import { User } from '../domain/User';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { UserInfo } from '../domain/UserInfo';


@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {

  user: User = new User();
  loading = false;
  error = '';
  
  constructor(private router: Router, private userService:UserService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    let loadingElement = document.querySelector('#loading');
    if(loadingElement){
      loadingElement.remove();
    }
  }
  
  onSubmitClick(): void {
	   if (!this.user.username) { return; }
	   if (!this.user.password) { return; }
	   this.loading = true;
	   this.userService.create(this.user)
		     .subscribe(userInfo => 
		           this.validateResponse(userInfo)
	    		  );
  }
    
  validateResponse(userInfo:UserInfo): void {
    	 if (userInfo && userInfo.token){ 
                localStorage.setItem('currentUser', userInfo.token);
                localStorage.setItem('currentUserName', userInfo.user.username);
	              this.router.navigateByUrl('/home');
	 			} else {
	 			    this.error = 'No se ha podido crear la cuenta';
                    this.loading = false;
    				}
  }

}
