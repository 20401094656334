import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { Session } from '../util/Session';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  homePath: string;
  isOperator: boolean;
  isAdmin: boolean;
  isSifeme: boolean;
  username: string;

  constructor(private router: Router, private userService: UserService) { }

  ngOnInit() {
    this.homePath = Session.getHomePath();
    this.username = localStorage.getItem('currentUserName');
    this.isOperator = Session.isOperator();
    this.isAdmin = Session.isAdmin();
    this.isSifeme = Session.isSifemeUser();
  }

  // NAVIGATION
  logout(): void {
    this.userService.logout().subscribe(result => {
      if (result) {
        //Session.logout();
        //this.leaveRoomIfJoined();
        //this.router.navigate(['/login']);
        console.log("Cerro sesion");
      } else {
        //this.openDialog("Error", "Hubo un error cerrando la sesión \nPor favor, volvé a intentarlo ", "ACEPTAR");
        console.log("Cerro sesion con error en el servicio");
      }
    });
    Session.logout();
    //this.leaveRoomIfJoined();
    this.router.navigate(['/login']);
  }

  updatePassword(): void {
    this.router.navigate(['/update-password']);
  }

  audit(): void {
    this.router.navigate(['/audit']);
  }

  affiliates(): void {
    this.router.navigate(['/affiliate']);
  }

  users(): void {
    this.router.navigate(['/user']);
  }

  agreement(): void {
    this.router.navigate(['/agreement']);
  }

  doctors(): void {
    this.router.navigate(['/doctor']);
  }

  recordings(): void {
    this.router.navigate(['/recording']);
  }

  indicators(): void {
    this.router.navigate(['/indicators']);
  }

  clinicHistory(): void {
    this.router.navigate(['/clinic-history']);
  }

  //END NAVIGATION

}
