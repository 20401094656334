import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Interview } from './domain/Interview';
import { catchError } from 'rxjs/operators';

@Injectable()
export class InterviewService extends BaseService {

  private getListURL = `${environment.host}/interview`;  // URL to web api

  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  getList(): Observable<Interview[]> {
    return this.http.get<Interview[]>(`${this.getListURL}`, { headers: this.getHeaders() })
      .pipe(
      catchError(this.handleError('getInterviewList', []))
      );
  }

}
