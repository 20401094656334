import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { environment } from '../environments/environment';
import { Agreement } from './domain/Agreement';
import { HttpClient } from '@angular/common/http';
import { catchError ,  timeout } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GeneralResponse } from './domain/GeneralResponse';
import { AgreementResult } from './domain/AgreementResult';

@Injectable()
export class AgreementService extends BaseService {

  private agreementURL = `${environment.host}/agreement`;
  private agreementV2URL = `${environment.api2}/agreement`;


  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  getAgreementList(): Observable<Agreement[]> {
    return this.http.get<Agreement[]>(this.agreementURL, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError<Agreement[]>('agreement list'))
      );
  }

  getAgreementListFilteredV2(sifeme: number): Observable<Agreement[]> {
    return this.http.get<Agreement[]>(`${this.agreementV2URL}?sifeme=${sifeme}`, { headers: this.getHeaders() })
  }

  createAgreement(agreement: Agreement): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(this.agreementURL, agreement, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError<GeneralResponse>('create agreement'))
      );
  }

  uploadFile(agreementId: number, fileToUpload: File): Observable<AgreementResult> {
    const formData = new FormData();
    formData.append('files', fileToUpload, fileToUpload.name);
    return this.http.post<AgreementResult>(`${this.agreementURL}/${agreementId}/import`, formData, { headers: this.getHeadersWithoutContentType() })
      .pipe(timeout(180000),
        catchError(this.handleError<AgreementResult>('upload agreement'))
      );
  }

  uploadMainFile(agreementId: number, fileToUpload: File): Observable<Agreement> {
      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      return this.http.post<Agreement>(`${this.agreementURL}/${agreementId}/mainLogo`, formData, { headers: this.getHeadersWithoutContentType() })
        .pipe(timeout(180000),
          catchError(this.handleError<Agreement>('upload main logo'))
        );
    }

    uploadSecundaryFile(agreementId: number, fileToUpload: File): Observable<Agreement> {
      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      return this.http.post<Agreement>(`${this.agreementURL}/${agreementId}/smallLogo`, formData, { headers: this.getHeadersWithoutContentType() })
        .pipe(timeout(180000),
          catchError(this.handleError<Agreement>('upload small logo'))
        );
    }

   update(agreementId: number, agreement: Agreement): Observable<Agreement> {
    console.log("update", agreement);
    return this.http.put<Agreement>(`${this.agreementURL}/${agreementId}`, agreement, { headers: this.getHeaders() })
    .pipe(
      catchError(this.handleError<Agreement>('update agreement'))
    );
  }

  createAgreementV2(agreement: Agreement): Observable<any> {
    return this.http.post<any>(this.agreementV2URL, agreement);
  }

}
