import { Provider } from "./Provider";

export class Affiliate {
  id: number;
  affiliateGamId: string;
  username: string;
  email: string;
  password: string;
  newPassword: string;
  groupNumber:number;
  provider:Provider;
  enabled: boolean;
  lastPasswordResetDate: string;
  passwordExpired:boolean;
  firstname: string;
  lastname:string;
  patient:string;
  availableCalls: number;
  resendEmail: boolean;
  documentNumber:string;
  telephone: number;
  agreementId: number
  sifeme: number;
}