import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Affiliate } from "../domain/Affiliate";
import { Location } from "@angular/common";
import { ModalService } from "../home/modal.service";
import { AffiliateService } from "../affiliate.service";
import { UserService } from "../user.service";
import { Router } from "@angular/router";
import { Session } from "../util/Session";
import { AgreementService } from "../agreement.service";
import { Agreement } from "../domain/Agreement";

@Component({
  selector: "app-affiliate",
  templateUrl: "./affiliate.component.html",
  styleUrls: ["./affiliate.component.css"],
})
export class AffiliateComponent implements OnInit {
  @ViewChild("searchInput", { static: true }) searchInput: ElementRef;
  @ViewChild("f", { static: true }) formValues;

  affiliate: Affiliate = new Affiliate();
  agreements: Agreement[];
  agreementId: number;
  error: string;
  loading: boolean = false;
  isNewUser: boolean = false;
  isOperator: boolean = false;
  isAdmin: boolean = false;
  username: string;
  homePath: string;

  constructor(
    private affiliateService: AffiliateService,
    private location: Location,
    public modalService: ModalService,
    private router: Router,
    private userService: UserService,
    private agreementService: AgreementService
  ) {}

  ngOnInit() {
    this.username = localStorage.getItem("currentUserName");
    this.isOperator = Session.isOperator();
    this.isAdmin = Session.isAdmin();
    this.homePath = Session.getHomePath();
    this.getAgreements();
    this.isNewUser = true;
  }

  ngAfterViewInit() {
    let loadingElement = document.querySelector("#loading");
    if (loadingElement) {
      loadingElement.remove();
    }
  }

  resetForm() {
    this.isNewUser = true;
    this.searchInput.nativeElement.value = "";
    this.formValues.resetForm();
  }

  search(documentNumber) {
    this.affiliateService
      .getAffiliateByDocumentNumber(documentNumber)
      .subscribe(
        (affiliate: any) => {
          if (affiliate == null || affiliate == undefined) {
            this.openDialog(
              "Usuarios",
              "No hay usuarios con ese número de documento.",
              "ACEPTAR"
            );
          } else {
            try {
              this.affiliateService.getAffiliatExtraDataV2(affiliate.id).subscribe((affiliateContact) => {
                if(affiliateContact){
                  this.affiliate.telephone = affiliateContact.data.telephone;
                } else {
                  this.openDialog(
                    "Usuarios",
                    "No se encontró contacto del usuario.",
                    "ACEPTAR"
                  );
                }
              })
            } catch {
              this.openDialog(
                "Usuarios",
                "No se encontró contacto del usuario.",
                "ACEPTAR"
              );
            }
            this.affiliate = affiliate;
            this.affiliate.agreementId = affiliate.agreement.id;
            this.agreementId = affiliate.agreement.id;
            this.isNewUser = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  create(): void {
    this.affiliateService.create(this.affiliate).subscribe(
      (affiliate) => {
        try {
          this.affiliateService
            .createAffiliateExtraDataV2({
              ...this.affiliate,
              userId: affiliate.id,
              agreementId: this.agreementId,
            })
            .subscribe(
              (affiliateExtraData) => {
                if (affiliateExtraData != null) {
                  this.openDialog(
                    "Usuarios",
                    "Se ha creado el usuario.",
                    "ACEPTAR"
                  );
                }
                this.loading = false;
              },
              (error) => {
                this.loading = false;
                this.openDialog(
                  "Usuarios",
                  "Error al asociar usuario al convenio.",
                  "ACEPTAR"
                );
              }
            );
        } catch (err) {
          this.loading = false;
          this.openDialog(
            "Usuarios",
            "Error al asociar usuario al convenio.",
            "ACEPTAR"
          );
        }
      },
      (error) => {
        console.error("Error al crear el afiliado asdadsad:", error);
        let message = "Error al generar alta de usuario.";
        switch (error.error.code) {
          case 2001:
            message = "Error al generar alta de usuario: el email ya existe";
            break;
          case 2002:
            message = "Error al generar alta de usuario: el email es inválido";
            break;
          case 2005:
            message =
              "Error al generar alta de usuario: el número de documento ya está registrado";
            break;
        }
        this.loading = false;
        this.openDialog("Usuarios", message, "ACEPTAR");
      }
    );
  }

  update(): void {
    this.affiliateService
      .update(this.affiliate.id, this.affiliate)
      .subscribe((affiliate) => {
        if (affiliate != null) {
          try {
            this.affiliateService
              .updateAffiliateExtraDataV2({
                ...this.affiliate,
                userId: this.affiliate.id,
                agreementId: this.agreementId,
              })
              .subscribe((affiliateExtraData) => {
                if (affiliateExtraData != null) {
                  this.openDialog(
                    "Usuarios",
                    "Se ha modificado el usuario.",
                    "ACEPTAR"
                  );
                }
                this.loading = false;
              });
          } catch (err) {
            this.loading = false;
            this.openDialog(
              "Usuarios",
              "Error al modificar el usuario.",
              "ACEPTAR"
            );
          }
          this.openDialog(
            "Usuarios",
            "Se ha modificado el usuario.",
            "ACEPTAR"
          );
          if(this.affiliate.resendEmail){
            this.resetUser();
          }
        } else {
          this.openDialog("Usuarios", "El DNI/Email ya existe.", "ACEPTAR");
        }
        this.loading = false;
      });
  }

  newUser(): void {
    this.resetForm();
    this.affiliate = new Affiliate();
  }

  onSubmitClick(): void {
    this.loading = true;
    this.error = "";
    this.affiliate.sifeme = 0;
    const isSifeme = Session.isSifemeUser();
    if (isSifeme) {
      this.affiliate.sifeme = 1;
    }
    if (this.isNewUser) {
      this.create();
    } else {
      this.update();
    }
  }
  onCancelClick(): void {
    this.resetForm();
  }

  // DIALOGS
  // Dialog variables
  commonDialogId = "dialogId";
  dialogTitle: String;
  dialogText: String;
  dialogButtonText: String;

  openDialog(title: string, message: string, buttonLabel: string): void {
    this.dialogButtonText = buttonLabel;
    this.dialogText = message;
    this.dialogTitle = title;
    let modal = this.modalService.open(this.commonDialogId);
    modal.finalizeClick.subscribe((data) => {
      this.resetForm();
    });
  }

  selectAgreement(value: number) {
    this.agreementId = value;
  }

  getAgreements() {
    const isSifemeOperator = Session.isSifemeUser();
    this.agreementService
      .getAgreementListFilteredV2(Number(isSifemeOperator))
      .subscribe(
        (agreements) => {
          this.agreements = agreements;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  resetUser() {
    this.affiliateService
      .resetAffiliateV2({ ...this.affiliate, userId: this.affiliate.id })
      .subscribe((affiliate) => {
        if (affiliate != null) {
          this.openDialog(
            "Usuarios",
            "Se ha reseteado la contraseña del usuario.",
            "ACEPTAR"
          );
        }
      });
  }
}
