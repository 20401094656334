import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Location } from "@angular/common";
import { ModalService } from "../home/modal.service";
import { Router } from "@angular/router";
import { Session } from "../util/Session";
import { UserDTO } from "../domain/UserDTO";
import { UserService } from "../user.service";
import { Doctor } from "../domain/Doctor";
import { Speciality } from "../domain/Speciality";
import { SpecialityService } from "../speciality.service";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.css"],
})
export class UserComponent implements OnInit {
  @ViewChild("searchInput", { static: true }) searchInput: ElementRef;
  @ViewChild("f", { static: true }) formValues;

  userDTO: UserDTO = new UserDTO();
  error: string;
  confirmPasswordVar: string;
  loading: boolean = false;
  isNewUser: boolean = false;
  isAdmin: boolean = false;
  username: string;
  homePath: string;
  specialityList: Speciality[];

  constructor(
    private location: Location,
    public modalService: ModalService,
    private router: Router,
    private userService: UserService,
    private specialityService: SpecialityService
  ) {}

  ngOnInit() {
    this.username = localStorage.getItem("currentUserName");
    this.isAdmin = Session.isAdmin();
    this.homePath = Session.getHomePath();
    this.isNewUser = true;
    this.specialityService.getSpecialityList().subscribe((specialityList) => {
      this.specialityList = specialityList;
    });
  }

  ngAfterViewInit() {
    let loadingElement = document.querySelector("#loading");
    if (loadingElement) {
      loadingElement.remove();
    }
  }

  resetForm() {
    this.isNewUser = true;
    this.searchInput.nativeElement.value = "";
    this.formValues.resetForm();
  }

  search(email) {
    this.userService.getUserByEmail(email).subscribe(
      (userDTO) => {
        if (userDTO == null || userDTO == undefined) {
          this.openDialog(
            "Usuarios",
            "No hay usuarios con ese email.",
            "ACEPTAR"
          );
        } else {
          this.userDTO = userDTO;
          this.isNewUser = false;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  newUser(): void {
    this.resetForm();
    this.userDTO = new UserDTO();
  }

  onCancelClick(): void {
    console.log(this.userDTO);
    console.log(this.userDTO.specialityList);
    this.resetForm();
  }

  onSubmitClick(): void {
    console.log(this.userDTO);
    console.log(this.userDTO.specialityList);
    this.loading = true;
    this.error = "";
    if (this.isNewUser) {
      this.create();
    } else {
      this.update();
    }
  }

  create(): void {
    this.error = "";
    this.loading = true;
    this.userDTO.username = this.userDTO.email;
    if (this.userDTO.password === this.confirmPasswordVar) {
      console.log(this.userDTO);
      console.log(this.userDTO.specialityList);
      try {
        this.userService.createUser(this.userDTO).subscribe((userInfo: any) => {
          this.userService
            .createUserExtraData({ ...this.userDTO, userId: userInfo.id })
            .subscribe((userInfo: any) => {
              if (userInfo != null) {
                this.openDialog(
                  "Usuarios",
                  "Se ha creado el usuario.",
                  "ACEPTAR"
                );
              }
              this.loading = false;
            });
        });
      } catch (err) {
        this.loading = false;
        this.error = "Error al generar alta de usuario";
      }
    } else {
      this.error = "Las contraseñas no coinciden";
      this.loading = false;
    }
  }

  update(): void {
    this.userService.update(this.userDTO).subscribe((Doctor) => {
      if (Doctor != null) {
        this.openDialog("Usuarios", "Se ha modificado el usuario.", "ACEPTAR");
      } else {
        this.error = "El DNI/Email ya existe.";
      }
      this.loading = false;
    });
  }

  // DIALOGS
  // Dialog variables
  commonDialogId = "dialogId";
  dialogTitle: String;
  dialogText: String;
  dialogButtonText: String;

  openDialog(title: string, message: string, buttonLabel: string): void {
    this.dialogButtonText = buttonLabel;
    this.dialogText = message;
    this.dialogTitle = title;
    let modal = this.modalService.open(this.commonDialogId);
    modal.finalizeClick.subscribe((data) => {
      this.resetForm();
    });
  }
}
