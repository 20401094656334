import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Agreement } from '../domain/Agreement';
import { AgreementService } from '../agreement.service';
import { ModalService } from '../home/modal.service';
import { JsonPipe, Location } from '@angular/common';
import { Session } from '../util/Session';
import { AgreementResult } from '../domain/AgreementResult';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.css']
})
export class AgreementComponent implements OnInit {

  //@ViewChild('file') file
  @ViewChild('fileInput', { static: true })
  myInputVariable: ElementRef;

  public files: Set<File> = new Set();

  agreements: Agreement[];
  fonts: String[] = ["lato", "open_sans", "raleway", "roboto"];
  newAgreement: Agreement = new Agreement();
  fileToUpload: File = null;
  selectedFile: string;
  fileMainLogoToUpload: File = null;
  selectedMainLogoFile: string;
  fileSecundaryLogoToUpload: File = null;
  selectedSecundaryLogoFile: string;
  homePath: string;
  agreementId : number;
  currentFont : string;
  loading: boolean = false;

  constructor(private agreementService: AgreementService, public modalService: ModalService, private location: Location) { }

  ngOnInit() {
    this.homePath = Session.getHomePath();
    this.getAgreements();
    this.selectedFile = 'No seleccionó ningún archivo';
    this.selectedMainLogoFile = 'No seleccionó ningún archivo';
    this.selectedSecundaryLogoFile = 'No seleccionó ningún archivo';
  }



  getAgreements() {
    const isSifemeOperator = Session.isSifemeUser();
    this.agreementService.getAgreementListFilteredV2(Number(isSifemeOperator)).subscribe(
      agreements => {
        this.agreements = agreements;
      },
      err => {
        console.log(err);
      }
    );
  }

  handleFileInput(files: FileList) {
    if (files) {
      this.fileToUpload = files.item(0);
      this.selectedFile = files.item(0).name;
    }
  }

  handleFileMainLogoInput(files: FileList) {
    if (files) {
      this.fileMainLogoToUpload = files.item(0);
      this.selectedMainLogoFile = files.item(0).name;
    }
  }

  handleFileSecundaryLogoInput(files: FileList) {
    if (files) {
      this.fileSecundaryLogoToUpload = files.item(0);
      this.selectedSecundaryLogoFile= files.item(0).name;
    }
  }

  select(value: number){
    this.newAgreement = this.agreements.find(x => x.id == value);
    this.agreementId =  this.agreements.find(x => x.id == value).id;
    this.currentFont = this.agreements.find(x => x.id == value).font;
  }

  selectFont(value: string){
    console.log("current font "+value)
    this.currentFont = value;
  }

  uploadFile() {
    if (this.fileToUpload != null && this.agreementId != null) {
      this.agreementService.uploadFile(this.agreementId, this.fileToUpload)
        .subscribe(agreement => {
          if (agreement) {
             this.parseUploadResult(agreement);
          } else {
            this.openDialog("Convenios", "Error al actualizar convenio", "ACEPTAR")
          }
        });

    }
  }

  uploadFileMainLogo() {
    if (this.fileMainLogoToUpload != null && this.agreementId != null) {
      this.agreementService.uploadMainFile(this.agreementId, this.fileMainLogoToUpload)
        .subscribe(agreement => {
          if (agreement) {
            this.openDialog("Convenios", "Se actualizó el logo principal", "ACEPTAR")
          } else {
            this.openDialog("Convenios", "Error al actualizar logo principal", "ACEPTAR")
          }
        });
    } else {
        this.openDialog("Convenios", "Por favor, seleccioná una imagen y un convenio ha modificar", "ACEPTAR");
    }
  }

  uploadFileSecundaryLogo() {
    if (this.fileSecundaryLogoToUpload != null && this.agreementId != null) {
      this.agreementService.uploadSecundaryFile(this.agreementId, this.fileSecundaryLogoToUpload)
        .subscribe(agreement => {
          if (agreement) {
            this.openDialog("Convenios", "Se actualizó el logo secundario", "ACEPTAR")
          } else {
            this.openDialog("Convenios", "Error al actualizar logo secundario", "ACEPTAR")
          }
        });
    } else {
      this.openDialog("Convenios", "Por favor, seleccioná una imagen y un convenio ha modificar", "ACEPTAR");
    }
  }


  parseUploadResult(agreement: AgreementResult){
    var totalErrorsQuantity : number = agreement.errors[0].rows.length + agreement.errors[1].rows.length + agreement.errors[2].rows.length +
                 agreement.errors[3].rows.length + agreement.errors[4].rows.length ;
    var message : string = "Convenio "+ agreement.agreement.name + " - Registros actualizados: " +  agreement.agreement.records + "<br><br>" +
    "Errores: " + totalErrorsQuantity+ "<br><br>";


    if (agreement.errors[0].rows.length > 0){
      message +=  "Información Incorrecta: "+agreement.errors[0].rows.length+ " en lineas " + agreement.errors[0].rows.toString()+ "<br>" ;
    }
    if (agreement.errors[1].rows.length > 0){
      message +=  "Duplicados:  "+agreement.errors[1].rows.length+ " en lineas " + agreement.errors[1].rows.toString()+ "<br>";
    }
    if (agreement.errors[2].rows.length > 0){
      message +=  "Nombre incorrecto o vacio:  "+agreement.errors[2].rows.length+ " en lineas " + agreement.errors[2].rows.toString()+ "<br>";
    }
    if (agreement.errors[3].rows.length > 0){
      message +=  "Apellido incorrecto o vacio:  "+agreement.errors[3].rows.length+ " en lineas " + agreement.errors[3].rows.toString()+ "<br>";
    }
    if (agreement.errors[4].rows.length > 0){
      message +=  "DNI incorrecto o vacio:  "+agreement.errors[4].rows.length+ " en lineas " + agreement.errors[4].rows.toString()+ "<br>";
    }

    this.openDialog("Covenios", message, "ACEPTAR");
  }

  updateWebsite(){
    if (this.agreementId != null) {
      this.loading = true;
      this.newAgreement.font = this.currentFont;
      this.agreementService.update(this.agreementId, this.newAgreement).subscribe(agreement =>  {
        if (agreement) {
          this.openDialog("Convenios", agreement.name + " actualizado", "ACEPTAR")
        } else {
          this.openDialog("Convenios", "Error al actualizar convenio", "ACEPTAR")
        }
        this.loading = false;
      });
    }
  }

  onCancelClick(): void {
    this.location.back();
  }

  // DIALOGS
  // Dialog variables
  commonDialogId = 'dialogId';
  dialogTitle: String;
  dialogText: String;
  dialogButtonText: String;

  openDialog(title: string, message: string, buttonLabel: string): void {
    this.dialogButtonText = buttonLabel;
    this.dialogText = message;
    this.dialogTitle = title;
    let modal = this.modalService.open(this.commonDialogId);
    modal.finalizeClick.subscribe((data) => {
      this.files =  new Set();
      this.fileToUpload = null;
      this.selectedFile = 'No seleccionó ningún archivo';
      this.myInputVariable.nativeElement.value = "";
    });
  }

  ngAfterViewInit() {
    let loadingElement = document.querySelector('#loading');
    if(loadingElement){
      loadingElement.remove();
    }
  }


}
