export class UserDTO {
    id:number;
    firstName:string;
    lastName:string;
    username: string;
    password: string;
    email: string;
    documentNumber:string;
    age:number;
    sex:string;
    nationalEnrollment:string;
    administrator: boolean;
    role:string;
    specialityList: number[];
    telephone: string;
    sifeme: number;
  }