import { UserInfo } from "../domain/UserInfo";

export class Session {

  public static login(userInfo: UserInfo) {
    localStorage.setItem('currentUser', userInfo.token);
    localStorage.setItem('currentUserName', userInfo.user.username);
    localStorage.setItem("sessionExpired", "false")
    localStorage.setItem("twilioToken", userInfo.twilioToken)
    localStorage.setItem('currentUserAuthority', userInfo.user.authorities.map(item => item.authority).join(", "));
  }

  public static isSessionExpired(): boolean {
    return localStorage.getItem("sessionExpired") == "true";
  }

  public static logout() {
    // clear token remove user from local storage to log user out
    localStorage.removeItem('sifemeUserCheck');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentUserName');
    localStorage.removeItem('currentUserAuthority');
    localStorage.removeItem("twilioToken")
  }

  public static sessionExpired() {
    this.logout();
    if (!this.isSessionExpired()) {
      localStorage.setItem('sessionExpired', "true");
      console.log(`sessionExpired - logout`);
    }
  }

  public static isAdmin(): boolean {
    var splitAuthorities = localStorage.getItem('currentUserAuthority').split(",");

    for (var i = 0; i < splitAuthorities.length; i++) {
      if (splitAuthorities[i] == 'ROLE_ADMIN') {
        return true;
      }
    }
    return false;
  }

  public static isOperator(): boolean {
    var splitAuthorities = localStorage.getItem('currentUserAuthority').split(",");

    for (var i = 0; i < splitAuthorities.length; i++) {
      if (splitAuthorities[i] == 'ROLE_OPERATOR') {
        return true;
      }
    }
    return false;
  }

  public static isSifemeUser(): boolean {
    const isSifemeUser = localStorage.getItem('sifemeUserCheck');
    return isSifemeUser === "true";
  }

  public static isDoctor(): boolean {
    var splitAuthorities = localStorage.getItem('currentUserAuthority').split(",");

    for (var i = 0; i < splitAuthorities.length; i++) {
      if (splitAuthorities[i] == 'ROLE_DOCTOR') {
        return true;
      }
    }
    return false;
  }
  
  /*public static getHomePath(): string {
  	if (this.isDoctor()) {
  		return "/home"
  	} else {
  		return "/affiliate"; 
  	}
  }*/

  public static getHomePath(): string {
  	if (this.isOperator()) {
  		return "/affiliate"
  	}
    else {
  		return "/home"; 
  	}
  }

}