import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateUserComponent } from './create-user/create-user.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './guard/auth.guard';
import { AdminOrOperatorGuard } from './guard/adminOrOperator.guard';
import { DoctorComponent } from './doctor/doctor.component';
import { AdminGuard } from './guard/admin.guard';
import { RecordingComponent } from './recording/recording.component';
import { AuditComponent } from './audit/audit.component';
import { AgreementComponent } from './agreement/agreement.component';
import { AddagreementComponent } from './agreement/addagreement/addagreement.component';
import { AffiliateComponent } from './affiliate/affiliate.component';
import { UserComponent } from './user/user.component';
import { IndicatorComponent } from './indicator/indicator.component';
import { ClinicHistoryComponent } from './clinic-history/clinic-history.component';
import { AdminOrAnyOperatorGuard } from './guard/adminOrAnyOperator';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'create-user', component: CreateUserComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'update-password', component: UpdatePasswordComponent, canActivate: [AuthGuard] },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'user', component: UserComponent, canActivate: [AdminOrAnyOperatorGuard]},
  { path: 'doctor', component: DoctorComponent, canActivate: [AdminOrOperatorGuard]},
  { path: 'recording', component: RecordingComponent, canActivate: [AdminOrOperatorGuard]},
  { path: 'audit', component: AuditComponent, canActivate: [AdminOrOperatorGuard]},
  { path: 'agreement', component: AgreementComponent, canActivate: [AdminOrAnyOperatorGuard]},
  { path: 'agreement/add', component: AddagreementComponent, canActivate: [AdminOrAnyOperatorGuard]},
  { path: 'affiliate', component: AffiliateComponent, canActivate: [AdminOrAnyOperatorGuard]},
  { path: 'indicators', component: IndicatorComponent, canActivate: [AdminOrOperatorGuard]},
  { path: 'clinic-history', component: ClinicHistoryComponent, canActivate: [AdminOrOperatorGuard]}
];


@NgModule({
  imports: [ RouterModule.forRoot(routes, {useHash: true}) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
