<!DOCTYPE html>
<html>

<head>
	<title>Recuperar contrase�a</title>
	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
	<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
	<!-- Include in <head> to load fonts from Google -->
	<link href='https://fonts.googleapis.com/css?family=Lato:100italic' rel='stylesheet' type='text/css'>
	<link href='https://fonts.googleapis.com/css?family=Lato:100' rel='stylesheet' type='text/css'>
	<link href='https://fonts.googleapis.com/css?family=Lato:normal' rel='stylesheet' type='text/css'>
	<link href='https://fonts.googleapis.com/css?family=Lato:300' rel='stylesheet' type='text/css'>
</head>

<body style="height: 100%; padding-right: 20px; width: 100vw">
	<app-menu></app-menu>

	<div class="searchDiv" xLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="0px" fxLayoutGap.xs="0">
		<span fxFlex="50%" fxFlexOrder="1" class="title" >Listado de Medicos</span>
		<div fxFlex="50%" fxFlexOrder="2" style="display: flex; justify-content: flex-end; align-items: center">
			<input style="float: right; margin-right: 30px;" type="text"  placeholder="Buscar..." class="filter" [formControl]="doctorCtrl">
		</div> 
	</div>

	<div class="container" style="width: 100%">
		<div class="row" style="margin: 30px; ">
			<div class="col">
				<section class="main">
					<table class="table table-bordered table-striped tabla">
						<thead>
							<tr class="table-title">
								<th>NOMBRE Y APELLIDO</th>
								<th>E-MAIL</th>
								<th>EDAD</th>
								<th>D.N.I</th>
								<th>STATUS</th>
								<th>HABILITADO</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let doctor of doctors | paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
								<td>{{doctor.firstName}} {{doctor.lastName}}</td>
								<td>{{doctor.email}}</td>
								<td>{{doctor.age}}</td>
								<td>{{doctor.documentNumber}}</td>
								<td *ngIf="doctor.online">On line<span class="online" style="margin-left:5px"></span></td>
								<td *ngIf="!doctor.online">Off line<span class="offline" style="margin-left:5px"></span></td>
								<td *ngIf="doctor.enabled"><input (click)="onEnabledDisableClick($event,doctor.id,doctor.enabled)" type="checkbox"checked></td>
								<td *ngIf="!doctor.enabled"><input (click)="onEnabledDisableClick($event,doctor.id,doctor.enabled)" type="checkbox"></td>
							</tr>
						</tbody>
					</table>
				</section>
			</div>
		</div>
	</div>
	<div class="pagination-controls">
		<pagination-controls class="my-pagination" (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
	</div>
	<ng-progress></ng-progress>
	
	<app-modal [useTwoButtons]='false' [modalTitle]="dialogTitle" [buttonTitle]="dialogButtonText" [blocking]='false' [modalId]='commonDialogId'>
		<div>{{dialogText}}</div>
	</app-modal>
</body>

</html>