import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AgreementService } from '../agreement.service';
import { Agreement } from '../domain/Agreement';
import { ClinicHistoryDTO } from '../domain/ClinicHistoryDTO';
import { FileResponseDTO } from '../domain/FileResponseDTO';
import { MedicalRecordService } from '../medical-record.service';
import { StringUtil } from '../util/StringUtil';

@Component({
  selector: 'app-clinic-history',
  templateUrl: './clinic-history.component.html',
  styleUrls: ['./clinic-history.component.css']
})
export class ClinicHistoryComponent implements OnInit {

  searchForm: FormGroup;
  clinicalHistories: ClinicHistoryDTO[];
  agreements: Agreement[];
  selectedAgreement: number;
  searchParams: String;
  file: FileResponseDTO;
  loading: boolean = false;
  showClinicalHistories: boolean = false;
  notFound: boolean = false;


  constructor(private agreementService: AgreementService,
    private medicalRecordService: MedicalRecordService) { }

  ngOnInit() {

    this.searchForm = new FormGroup({
      from: new FormControl(null, [Validators.required]),
      to: new FormControl(null, [Validators.required]),
      documentNumber: new FormControl(null, [Validators.minLength(7), Validators.maxLength(9)])
    });

    this.agreementService.getAgreementList().subscribe(agreements => {
      this.agreements = agreements;
      this.dismissLoading();
    },
      err => {
        this.loading = false;
        console.log(err);
      });

  }

  search(from: Date, to: Date, videoCallId: String, documentNumber: String): void {
    this.loading = true;
    this.medicalRecordService.getMedicalRecordListByFilters(this.generateParamsPath(from, to, videoCallId, documentNumber)).subscribe(clinicalHistories => {
      console.log(this.clinicalHistories);
      this.loading = false;

      if (clinicalHistories == null || clinicalHistories == undefined || clinicalHistories.length == 0) {
        this.notFound = true;
        this.showClinicalHistories = false;
        this.loading = false;
      } else {
        this.notFound = false;
        this.clinicalHistories = clinicalHistories;
        this.showClinicalHistories = true;
        this.loading = false;
      }
    },
      err => {
        console.log(err);
        this.loading = false;
      });
  }

  select(agreement: number) {
    console.log("select " + JSON.stringify(agreement));
    this.selectedAgreement = agreement;
    console.log("ID " + this.selectedAgreement);
  }

  // UTILS
  toDate(date: number): string {
    return StringUtil.toDate(date);
  }

  generateParamsPath(from: Date, to: Date, videoCallId: String, documentNumber: String): String {

    this.searchParams = '?';

    if (from) {
      this.searchParams = this.searchParams + 'startDate=' + from;
    }

    if (to) {
      this.searchParams = this.searchParams + '&endDate=' + to;
    }

    if (videoCallId) {
      this.searchParams = this.searchParams + '&videoCallId=' + videoCallId;
    }

    if (documentNumber) {
      this.searchParams = this.searchParams + '&documentNumber=' + documentNumber;
    }

    if (this.selectedAgreement) {
      this.searchParams = this.searchParams + '&agreementId=' + this.selectedAgreement;
    }

    console.log("Parametros " + this.searchParams);

    return this.searchParams;
  }

  downloadPdf(): void {

    this.loading = true;

    console.log("Parametros de busqueda: " + this.searchParams);

    this.medicalRecordService.getFileInBase64ToDownload(this.searchParams).subscribe(file => {

      this.file = file;

      const arrayBuffer = this.base64ToArrayBuffer(this.file.fileInBase64);

      this.createAndDownloadBlobFile(arrayBuffer, 'historias-clinicas');

      this.loading = false;
    },
      err => {
        console.log(err);
      });
  }

  base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  createAndDownloadBlobFile(body, filename, extension = 'pdf') {
    const blob = new Blob([body]);
    const fileName = `${filename}.${extension}`;

    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  dismissLoading() {
    let loadingElement = document.querySelector('#loading');
    if (loadingElement) {
      loadingElement.remove();
    }
  }

}
