import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { PrescriptionDTO } from './domain/PrescriptionDTO';
import { catchError } from 'rxjs/operators';
import { Prescription } from './domain/Prescription';
import { GeneralResponse } from './domain/GeneralResponse';

@Injectable()
export class PrescriptionService extends BaseService{

  private prescriptionURL = `${environment.host}/prescription`;  
  private getPrescriptionListURL = `${environment.host}/prescription/videocall/`;
  
	constructor(private injector: Injector, private http: HttpClient) {
		super(injector);
	}

	save(prescription: PrescriptionDTO): Observable<PrescriptionDTO> {
		return this.http.post<PrescriptionDTO>(this.prescriptionURL, prescription, { headers: this.getHeaders() })
			.pipe(
			catchError(this.handleError<PrescriptionDTO>('save'))
			);
  }

  delete(prescriptionId: number): Observable<GeneralResponse> {
    return this.http.delete<GeneralResponse>(`${this.prescriptionURL}/${prescriptionId}`, { headers: this.getHeaders() })                                       
			.pipe(
			catchError(this.handleError<GeneralResponse>('delete'))
			);
  }

  getPrescriptionList(videocallId: number): Observable<Prescription[]> {
    return this.http.get<Prescription[]>(`${this.getPrescriptionListURL}${videocallId}`, { headers: this.getHeaders() })                                    
			.pipe(
			catchError(this.handleError<Prescription[]>('Prescription List'))
			);
  }
  
}
