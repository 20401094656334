import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { environment } from "../environments/environment";
import { Affiliate } from "./domain/Affiliate";

@Injectable()
export class AffiliateService extends BaseService {
  private getAffiliateByDocumentNumberURL = `${environment.host}/affiliate/documentnumber/`;
  private createAffiliateURL = `${environment.host}/affiliate`;
  private updateAffiliateURL = `${environment.host}/affiliate/`;
  private affiliateExtraDataV2URL = `${environment.api2}/patient/extra-data`;
  private getAffiliateExtraDataV2URL = `${environment.api2}/patient`;
  private resetAffiliateV2URL = `${environment.api2}/patient/reset`;

  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  getAffiliateByDocumentNumber(documentNumber: number): Observable<Affiliate> {
    return this.http
      .get<Affiliate>(
        `${this.getAffiliateByDocumentNumberURL}${documentNumber}`,
        { headers: this.getHeaders() }
      )
      .pipe(
        catchError(this.handleError<Affiliate>("getAffiliateByDocumentNumber"))
      );
  }

  create(affiliate: Affiliate): Observable<Affiliate> {
    return this.http.post<Affiliate>(`${this.createAffiliateURL}`, affiliate, {
      headers: this.getHeaders(),
    });
  }

  createAffiliateExtraDataV2(affiliate: any): Observable<any> {
    return this.http.post<any>(this.affiliateExtraDataV2URL, affiliate);
  }

  update(id: number, affiliate: Affiliate): Observable<Affiliate> {
    return this.http
      .put<Affiliate>(`${this.updateAffiliateURL}${id}`, affiliate, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError<Affiliate>("UpdateAffiliate")));
  }

  getAffiliatExtraDataV2(id: number): Observable<any> {
	return this.http.get<any>(`${this.getAffiliateExtraDataV2URL}/${id}/extra-data`);
  }

  updateAffiliateExtraDataV2(affiliate: any): Observable<any> {
    return this.http.put<any>(this.affiliateExtraDataV2URL, affiliate);
  }

  resetAffiliateV2(affiliate: any): Observable<any> {
    return this.http.post<any>(this.resetAffiliateV2URL, affiliate);
  }
}
