import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UserService } from '../user.service';
import { GeneralResponse } from '../domain/GeneralResponse';
import { UserInfo } from '../domain/UserInfo';
import { Doctor } from '../domain/Doctor';
import { NgForm, FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ModalService } from '../home/modal.service';
import { Session } from '../util/Session';


@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.component.html',
  styleUrls: ['./doctor.component.css']
})
export class DoctorComponent implements OnInit {
   
  doctors: Doctor[];
  p : number;
  loading = false;
  error: string;
  response: GeneralResponse;
  homePath: string;

  constructor(private userService: UserService, private location: Location, public modalService: ModalService)  { }

  doctorCtrl = new FormControl();

  ngOnInit() {
    this.homePath = Session.getHomePath();
    this.getAllDoctors();
    this.doctorCtrl.valueChanges.subscribe(doctors => {
      this.getDoctorsByFilter(this.doctorCtrl.value);
    });
  }

  ngAfterViewInit() {
    let loadingElement = document.querySelector('#loading');
    if(loadingElement){
      loadingElement.remove();
    }
  }

  getAllDoctors() {
    this.userService.getDoctorList().subscribe(
      doctors => {
        this.doctors = doctors;
      },
      err => {
        console.log(err);
      }
    );
  }

  getDoctorsByFilter(filter: string) {
    console.log(localStorage.getItem('currentUser'));
    this.userService.getDoctorByFilter(filter).subscribe(
      doctors => {
        this.doctors = doctors;
      },
      err => {
        console.log(err);
      }
    );
  }

  onEnabledDisableClick($event, doctorId: number, enable: boolean): void {
    $event.preventDefault()
    if (enable) {
      console.log('Estaba enable');
      this.userService.disableUser(doctorId).subscribe(
        generalResponse => {
          if (generalResponse) {
            this.openDialog("Médicos", "Se ha deshabilitado el usuario.", "ACEPTAR")
          }
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.userService.enableUser(doctorId).subscribe(
        generalResponse => {
          if (generalResponse) {
            this.openDialog("Médicos", "Se ha habilitado el usuario.", "ACEPTAR")
          }
        }
      );
    }
  }

  // DIALOGS
  // Dialog variables
  commonDialogId = 'dialogId';
  dialogTitle: String;
  dialogText: String;
  dialogButtonText: String;

  openDialog(title: string, message: string, buttonLabel: string): void {
    console.log('dialog');
    this.dialogButtonText = buttonLabel;
    this.dialogText = message;
    this.dialogTitle = title;
    let modal = this.modalService.open(this.commonDialogId);
    console.log(modal);
    modal.finalizeClick.subscribe((data) => {
      this.getAllDoctors();
    });
  }


}