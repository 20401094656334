import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { RecordingDTO } from './domain/RecordingDTO';
import { GeneralResponse } from './domain/GeneralResponse';

@Injectable()
export class RecordingService extends BaseService{

  private recordingURL = `${environment.host}/recording`;  
  private compositionURL = `${environment.host}/composition/`;


  constructor(private injector: Injector, private http: HttpClient) {
		super(injector);
  }

  getRecordings(date: number, doctorId: number): Observable<RecordingDTO[]> {
    return this.http.get<RecordingDTO[]>(`${this.recordingURL}/${date}/doctor/${doctorId}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError<RecordingDTO[]>('recording list'))
      );
  }

  getRecordingsByDate(date: number): Observable<RecordingDTO[]> {
    return this.http.get<RecordingDTO[]>(`${this.recordingURL}/${date}`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError<RecordingDTO[]>('recording list'))
      );
  }

  createComposition(roomSid: String): Observable<GeneralResponse> {
    return this.http.get<GeneralResponse>(`${this.compositionURL}${roomSid}/create`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError<GeneralResponse>('Composition'))
      );
  }

}