<!DOCTYPE html>
<html>

<head>
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
  <!-- Include in <head> to load fonts from Google -->
  <link href='https://fonts.googleapis.com/css?family=Lato:100italic' rel='stylesheet' type='text/css'>
  <link href='https://fonts.googleapis.com/css?family=Lato:100' rel='stylesheet' type='text/css'>
  <link href='https://fonts.googleapis.com/css?family=Lato:normal' rel='stylesheet' type='text/css'>
  <link href='https://fonts.googleapis.com/css?family=Lato:300' rel='stylesheet' type='text/css'>

</head>

<body>
  <app-menu></app-menu>

  <div fxLayout="column" fxLayoutGap="0px" fxLayoutGap.xs="10px" style="width: 100%; height: 90vh;"
    class="main-container">
    <div fxFlexOrder="1" fxFlex="20%">
      <legend class="title" style="margin-top:50px;">Nuevo Convenio</legend>
    </div>

    <div fxFlexOrder="2" fxFlex="70%" class="container" style="margin-left: 30px; margin-top: 30px;">

      <form name="form" (ngSubmit)="f.form.valid && onSubmitClick()" #f="ngForm" novalidate>
        <div style="text-align: center">

          <mat-form-field [hideRequiredMarker]="false">
            <input matInput [(ngModel)]="newAgreement.name" name="name" placeholder="Convenio" id="name" required>
          </mat-form-field>

          <mat-form-field [hideRequiredMarker]="false">
            <input matInput [(ngModel)]="newAgreement.telephone" name="telephone" placeholder="Telefono" id="telephone"
              required>
          </mat-form-field>

        </div>
         
        <div class="website" style="margin-left:20px;">
          <mat-form-field [hideRequiredMarker]="false">
            <input matInput [(ngModel)]="newAgreement.website" name="website" placeholder="Web" id="website" required>
          </mat-form-field>
        </div>

        <br>
        <div style="margin-top:30px; text-align: center">
          <button [disabled]="loading" mat-raised-button class="save-button" style="margin-left:30px;">GUARDAR</button>
          <button style="margin-left:30px" mat-raised-button class="cancel-button" type="button"
            (click)="onCancelClick()">CANCELAR</button>
        </div>

      </form>

    </div>
    
    <div fxFlex="10%" fxFlexOrder="2" style="margin-top: 20px">
        <mat-error>{{error}}</mat-error>
    </div>
    
  </div>

</body>

</html>