import { Component, OnInit } from "@angular/core";
import { Agreement } from "../../domain/Agreement";
import { AgreementService } from "../../agreement.service";
import { Location } from "@angular/common";
//import { GeneralResponse } from '../../domain/GeneralResponse';
import { Router } from "@angular/router";
import { Session } from "../../util/Session";

@Component({
  selector: "app-addagreement",
  templateUrl: "./addagreement.component.html",
  styleUrls: ["./addagreement.component.css"],
})
export class AddagreementComponent implements OnInit {
  newAgreement: Agreement = new Agreement();
  error: string;
  loading = false;
  homePath: string;

  constructor(
    private router: Router,
    private agreementService: AgreementService,
    private location: Location
  ) {}

  ngOnInit() {
    this.homePath = Session.getHomePath();
  }

  onSubmitClick(): void {
    this.error = "";
    this.loading = true;
    this.newAgreement.sifeme = 0;
    const isSifeme = Session.isSifemeUser();
    if (isSifeme) {
      this.newAgreement.sifeme = 1;
    }

    this.agreementService
      .createAgreementV2(this.newAgreement)
      .subscribe((generalResponse) => {
        if (generalResponse) {
          this.router.navigateByUrl("/agreement");
        } else {
          this.loading = false;
          this.error = "Error al guardar convenio";
        }
      });
  }

  onCancelClick(): void {
    this.location.back();
  }
}
