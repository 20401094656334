import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AuditDTO } from './domain/AuditDTO';
import { Observable } from 'rxjs';
import { GeneralResponse } from './domain/GeneralResponse';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuditService extends BaseService{

  private startAuditURL = `${environment.host}/audit/start`;	
  private endAuditURL = `${environment.host}/audit/end`;  

  constructor(private injector: Injector, private http: HttpClient) {
		super(injector);
  }

  startAudit(auditDTO: AuditDTO): Observable<number> {
		return this.http.post<number>(this.startAuditURL, auditDTO, { headers: this.getHeaders() })
			.pipe(
			catchError(this.handleError<number>('startAudit'))
			);
	}

  endAudit(auditDTO: AuditDTO): Observable<GeneralResponse> {
		return this.http.post<GeneralResponse>(this.endAuditURL, auditDTO, { headers: this.getHeaders() })
			.pipe(
			catchError(this.handleError<GeneralResponse>('endAudit'))
			);
	}

}