<!DOCTYPE html>
<html>

<head>
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
  <!-- Include in <head> to load fonts from Google -->
  <link href='https://fonts.googleapis.com/css?family=Lato:100italic' rel='stylesheet' type='text/css'>
  <link href='https://fonts.googleapis.com/css?family=Lato:100' rel='stylesheet' type='text/css'>
  <link href='https://fonts.googleapis.com/css?family=Lato:normal' rel='stylesheet' type='text/css'>
  <link href='https://fonts.googleapis.com/css?family=Lato:300' rel='stylesheet' type='text/css'>

</head>

<body style="height: 100vh">
  <app-menu></app-menu>
  <div style="margin-left: 30px; margin-top: 40px; width: 75%;">
    <span class="title">Auditoria</span>
  </div>

  <div *ngIf="!auditing && !loading">
    <div class="container">
      <section class="main" style="margin-left: 30px; margin-right:  30px; margin-top: 30px;">
        <table class="table table-bordered table-striped table" >
          <thead>
            <tr class="table-title">
              <th>FECHA INICIO</th>
              <th>PACIENTE</th>
              <th>DOCTOR</th>
              <th>AUDITAR</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let videocall of videocalls | paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
              <td>{{toDate(videocall.startDate)}}</td>
              <td>{{videocall.affiliate.username}}</td>
              <td>{{videocall.doctor.username}}</td>
              <td><i (click)="getVideocallToAudit(videocall.id)" class="material-icons">play_circle_filled</i></td>
            </tr>
          </tbody>
        </table> 
        <div *ngIf="videocalls.length > 0" class="pagination-controls" style="margin-left: -30px">   
          <pagination-controls class="my-pagination" (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
        </div>
        <button mat-raised-button class="refresh-button" type="button" (click)="onRefreshClick()">ACTUALIZAR</button>
      </section>
    </div>
  </div>

  <div *ngIf="loading"><img id="loader" src="./assets/images/loading.gif"></div>

  <div *ngIf="error" style="margin-left: 30px; margin-top:70px;">
    <span class="title">Error al unirse a la videollamada</span>
  </div>

  <div *ngIf="auditing">
    <div class="main-container" fxFlexOrder="1" fxLayout fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="0px"
      fxLayoutGap.xs="0">
      <div id="main-left" class="main-left" fxFlex="50%" fxFlexOrder="1">
        <div id="videocall-container" class="videocall-container" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="0px">
          <div id="user-video" fxFlex="100%" fxFlexOrder="1" style="width: 100%;align-self: center">
          </div>
          <div *ngIf="!attachUserTracks">
            <span class="title">Usuario</span>
            <img src="./assets/images/home.png" style="width: 200px; height: 200px;  margin-left: auto; margin-right: auto; display: block;">
          </div>
        </div>
      </div>

      <div class="main-right" fxFlex="50%" id="main-right" fxFlexOrder="2">
        <div id="videocall-container" class="videocall-container" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="0px">
          <div id="doctor-video" fxFlex="100%" fxFlexOrder="1" style="width: 100%;align-self: center">
          </div>
          <div *ngIf="!attachDoctorTracks">
            <span class="title">Doctor</span>
            <img src="./assets/images/home.png" style="width: 200px; height: 200px;  margin-left: auto; margin-right: auto; display: block;">
          </div>
        </div>
      </div>
    </div>
    <form id="auditForm" [formGroup]="auditForm">
      <div style="margin-left:30px; margin-top: 10px;">
        <mat-form-field [hideRequiredMarker]="false" style="padding-top:5px;width: 80%; margin-bottom: 20px; max-height:100%; ">
          <textarea matInput matTextareaAutosize matAutosizeMinRows="1" matAutosizeMaxRows="6" type="text" [(ngModel)]="newAuditDTO.comment"
            #comment formControlName="comment" placeholder="Comentarios"></textarea>
        </mat-form-field>
      </div>
      <div fxflex="nogrow" style="flex: 0 1 auto; box-sizing: border-box;">
        <button style="margin-left:30px; margin-bottom: 20px" mat-raised-button class="save-button" type="button"
          (click)="onFinishAuditClick()" [disabled]="auditForm.invalid">FINALIZAR</button>
        <button style="margin-left:30px; margin-bottom: 20px" mat-raised-button class="cancel-button" type="button"
          (click)="onCancelClick()">CANCELAR</button>
      </div>
    </form>

  </div>

  <app-modal [useTwoButtons]='false' [modalTitle]="dialogTitle" [buttonTitle]="dialogButtonText" [blocking]='false'
    [modalId]='commonDialogId'>
    <div>{{dialogText}}</div>
  </app-modal>


</body>

</html>