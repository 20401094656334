import { Component, OnInit } from '@angular/core';
import { User } from '../domain/User';
import { UserService } from '../user.service';
import { Location } from '@angular/common';
import { GeneralResponse } from '../domain/GeneralResponse';
import { Session } from '../util/Session';


@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {
  user: User = new User();
  loading = false;
  error = '';
  confirmPasswordVar: string;
  homePath: string;

  constructor(private userService: UserService, private location: Location) { }

  ngOnInit() {
    this.homePath = Session.getHomePath();
  }

  ngAfterViewInit() {
    let loadingElement = document.querySelector('#loading');
    if(loadingElement){
      loadingElement.remove();
    }
  }

  onSubmitClick(): void {
    this.loading = true;
    this.error = '';
    if (this.user.newPassword === this.confirmPasswordVar) {
      this.userService.updatePassword(this.user)
        .subscribe(generalResponse =>
          this.validateResponse(generalResponse)
        );
    } else {
      this.error = 'Las contraseñas no coinciden';
      this.loading = false;
    }
  }

  validateResponse(generalResponse: GeneralResponse): void {
    if (generalResponse) {
      this.location.back();
    } else {
      this.error = 'Los datos son incorrectos';
      this.loading = false;
    }
  }

  onCancelClick(): void {
    this.location.back();
  }

}
