import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Indicator } from '../domain/Indicator';
import { IndicatorData } from '../domain/IndicatorData';
import { Videocall } from '../domain/Videocall';
import { IndicatorService } from '../indicator.service';
import { VideocallService } from '../videocall.service';

@Component({
  selector: 'app-indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.css']
})
export class IndicatorComponent implements OnInit {

  videocalls: Videocall[];
  indicators : Indicator[] = [];
  loading: boolean = false;
  searchForm: FormGroup;
  selectedIndicator : string;
  rows: string[] = [];
  items: string[][] = [];
  p : number;

  constructor(private indicatorService: IndicatorService) { }

  ngOnInit() {
    
    this.searchForm = new FormGroup({
      from: new FormControl(null, [Validators.required]),
      to: new FormControl(null, [Validators.required])
    });
    this.indicatorService.listAllIndicators().subscribe(indicators => {
      this.indicators = indicators;
      this.dismissLoading();
    },
      err => {
        console.log(err);
      });
  }

  dismissLoading() {
    let loadingElement = document.querySelector('#loading');
    if (loadingElement) {
      loadingElement.remove();
    }
  }

  search(from, to): void {
    this.loading = true;
    this.indicatorService.getIndicator(this.selectedIndicator, from, to).subscribe(
      data => {
          //this.videocalls = videocalls;
          console.log(JSON.stringify(data));
          this.rows = data.rows;
          this.items = data.items
          this.loading = false;  
          this.refreshIndicators();      
      },
      err => {
        this.loading = false;  
        console.log(err);
      }
    );

  }

  select (indicator:string){
    console.log("select " + JSON.stringify(indicator));
    this.selectedIndicator = indicator;
  }


  refreshIndicators() {
    //throw new Error('Method not implemented.');
  }

}
