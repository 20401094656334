import { Authority } from "./Authority";

export class User {
  id: number;
  username: string;
  email: string;
  password: string;
  newPassword: string;
  enabled: boolean;
  lastPasswordResetDate: string;
  passwordExpired:boolean;
  authorities: Authority[];
  sifeme: number;
}