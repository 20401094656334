<mat-toolbar class="toolbar-container">
  <img class="toolbar-title" src="./assets/images/logo_header.png" routerLink={{homePath}}>
  <span class="home-toolbar"></span>
  <div class="centered-horizontally-container">

    <span class="subtitle" style="margin-top:0px;line-height: 50px; "><img src="./assets/images/user.png" style="margin-right:5px;margin-top:0px;height: 50px; width: 50px; vertical-align: middle;"> {{username}}<img [matMenuTriggerFor]="menu" style="margin-top:0px;vertical-align: middle;" class="toolbar-icon" src="./assets/images/arrow_menu.png"></span>

    <mat-menu #menu="matMenu" style="padding-top:0px; ">
      <div *ngIf="isAdmin && !isSifeme">
        <button (click)="audit()" class="menu-button" mat-menu-item style="text-align: left">
          <span>Auditoría</span>
        </button>
        <button (click)="agreement()" class="menu-button" mat-menu-item style="text-align: left">
          <span>Convenios</span>
        </button>
        <button (click)="affiliates()" class="menu-button" mat-menu-item style="text-align: left">
          <span>Pacientes</span>
        </button>
        <button (click)="users()" class="menu-button" mat-menu-item style="text-align: left">
          <span>Usuarios</span>
        </button>
        <button (click)="doctors()" class="menu-button" mat-menu-item style="text-align: left">
          <span>Médicos</span>
        </button>
        <button (click)="recordings()" class="menu-button" mat-menu-item style="text-align: left">
          <span>Grabaciones</span>
        </button>
        <button (click)="indicators()" class="menu-button" mat-menu-item style="text-align: left">
          <span>Indicadores</span>
        </button>
        <button (click)="clinicHistory()" class="menu-button" mat-menu-item style="text-align: left">
          <span>Historias clínicas</span>
        </button>
      </div>
      <div *ngIf="isOperator && !isSifeme">       
        <button (click)="affiliates()" class="menu-button" mat-menu-item style="text-align: left">
          <span>Pacientes</span>
        </button>
        <button (click)="indicators()" class="menu-button" mat-menu-item style="text-align: left">
          <span>Indicadores</span>
        </button>
        <button (click)="clinicHistory()" class="menu-button" mat-menu-item style="text-align: left">
          <span>Historias clínicas</span>
        </button>
      </div>
      <div *ngIf="isOperator && isSifeme">       
        <button (click)="affiliates()" class="menu-button" mat-menu-item style="text-align: left">
          <span>Pacientes</span>
        </button>
      </div>
      <div *ngIf="isAdmin && isSifeme">    
        <button (click)="agreement()" class="menu-button" mat-menu-item style="text-align: left">
          <span>Convenios</span>
        </button>   
        <button (click)="affiliates()" class="menu-button" mat-menu-item style="text-align: left">
          <span>Pacientes</span>
        </button>
      </div>
      <button (click)="updatePassword()" class="menu-button" mat-menu-item>
        <span>Cambiar Contraseña</span>
      </button>
      <button (click)="logout()" class="menu-button" mat-menu-item style="text-align: left">
        <span>Cerrar Sesión</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>