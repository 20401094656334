import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // <-- NgModel lives here
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AuthGuard } from './guard/auth.guard';
import { UserService } from './/user.service';
import { MedicalRecordService } from './/medical-record.service';
import { RecordingService } from './/recording.service';
import { AuditService } from './/audit.service';
import { AgreementService } from './/agreement.service';
import { AffiliateService } from './/affiliate.service';
import { SpecialityService } from './speciality.service';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { AppRoutingModule } from './/app-routing.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { HomeComponent} from './home/home.component';
import { VideocallService } from './videocall.service';
import { ReasonService } from './reason.service';
import { DoctorComponent } from './doctor/doctor.component';
import { AdddoctorComponent } from './doctor/adddoctor/adddoctor.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatChipsModule} from '@angular/material/chips';

import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatDialogModule} from '@angular/material/dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import { ModalService } from './home/modal.service';
import { ModalComponent } from './home/modal.component';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
import { InterviewService } from './interview.service';
import {NgxPaginationModule} from 'ngx-pagination';
import { AdminGuard } from './guard/admin.guard';
import { AdminOrOperatorGuard } from './guard/adminOrOperator.guard';
import { AdminOrAnyOperatorGuard } from './guard/adminOrAnyOperator';
import { RecordingComponent } from './recording/recording.component';
import { AuditComponent } from './audit/audit.component';
import { AgreementComponent } from './agreement/agreement.component';
import { AddagreementComponent } from './agreement/addagreement/addagreement.component';

import { AffiliateComponent } from './affiliate/affiliate.component';
import { UserComponent } from './user/user.component';
import { PopupPrescriptionComponent } from './popup-prescription/popup-prescription.component';
import { PrescriptionService } from './prescription.service';
import { IndicatorComponent } from './indicator/indicator.component';
import { IndicatorService } from './indicator.service';
import { SortDirective } from './directive/sort.directive';
import { MenuComponent } from './menu/menu.component';
import { ClinicHistoryComponent } from './clinic-history/clinic-history.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CreateUserComponent,
    ForgotPasswordComponent,
    UpdatePasswordComponent,
    HomeComponent,
    ModalComponent,
    DoctorComponent,
    AdddoctorComponent,
    RecordingComponent,
    AuditComponent,
    AgreementComponent,
    AddagreementComponent,
    AffiliateComponent,
    UserComponent,
    PopupPrescriptionComponent,
    IndicatorComponent,
    SortDirective,
    MenuComponent,
    ClinicHistoryComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatCardModule,
    MatSelectModule,
    MatIconModule,
    MatDialogModule,
    MatToolbarModule,
    MatChipsModule,
    MatAutocompleteModule,
    FlexLayoutModule,
    MatMenuModule,
    NgProgressModule.forRoot({
      color: "#f57a7e",
      ease: "linear",
      spinner: false
    }),
    NgProgressRouterModule,
    NgProgressHttpModule,
    NgxPaginationModule
    ],entryComponents: [PopupPrescriptionComponent
  ],
  providers: [AuthGuard, AdminGuard, AdminOrOperatorGuard, AdminOrAnyOperatorGuard, ReactiveFormsModule, IndicatorService,
    ModalService, UserService, ReasonService, MedicalRecordService, VideocallService, InterviewService, RecordingService, AuditService, AgreementService, AffiliateService, PrescriptionService, SpecialityService],
  bootstrap: [AppComponent],
  exports: [ ModalComponent ]
})
export class AppModule { }
