// modal/modal.component.ts
import { Component, Input, OnInit, HostListener, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ModalService } from './modal.service';

@Component({
    selector: 'app-modal',
    styleUrls: ['./modal.scss'],
    template: `
      <div *ngIf="isOpen" [ngClass]="{'closed': !isOpen}">
        <div class="modal-overlay" (click)="close(false)"></div>
        <div class="modal">
          <div class="title" *ngIf="modalTitle">
            <span class="title-text">{{ modalTitle }}</span>
          </div>

          <div class="body">
            <ng-content style="text-align: left;">
            </ng-content>
            <div style="text-align: right;">
              <button style="margin-top:20px;" (click)="buttonOne(false)"  class="button">{{buttonTitle}}</button>            
              <button style="margin-top:20px;" *ngIf="button2Title" (click)="buttonTwo(false)" class="button">{{button2Title}}</button>            
            </div>
         </div>
        </div>
      </div>
    `
})
export class ModalComponent implements OnInit {
  @Input() modalId: string;
  @Input() modalTitle: string;
  @Input() buttonTitle: string;
  @Input() button2Title: string;
  @Input() blocking = false;
  @Input() useTwoButtons = false;
  @Output() finalizeClick: EventEmitter<number> = new EventEmitter<number>();
  isOpen = false;

  @HostListener('keyup') onMouseEnter(event) {
    this.keyup(event);
  }

  constructor(private modalService: ModalService, private ref: ChangeDetectorRef,) {
  }

  ngOnDestroy() {
    if (!this.ref['destroyed']) {
      this.ref.detectChanges();
    }
  }

  ngOnInit() {
    this.modalService.registerModal(this);
    this.modalService.close(this.modalId, false);
  }

  buttonOne(checkBlocking = false): void {
    this.modalService.close(this.modalId, checkBlocking);
    this.finalizeClick.emit(1);
  }

  buttonTwo(checkBlocking = false): void {
    this.modalService.close(this.modalId, checkBlocking);
    this.finalizeClick.emit(2);
  }

  private keyup(event: KeyboardEvent): void {
    if (event && event.keyCode === 27) {
      this.modalService.close(this.modalId, true);
    }
  }
}