<!DOCTYPE html>
<html>

<head>
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
    <!-- Include in <head> to load fonts from Google -->
    <link href='https://fonts.googleapis.com/css?family=Lato:100italic' rel='stylesheet' type='text/css'>
    <link href='https://fonts.googleapis.com/css?family=Lato:100' rel='stylesheet' type='text/css'>
    <link href='https://fonts.googleapis.com/css?family=Lato:normal' rel='stylesheet' type='text/css'>
    <link href='https://fonts.googleapis.com/css?family=Lato:300' rel='stylesheet' type='text/css'>

    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
</head>

<body style="height: 100vh">
    <app-menu></app-menu>

    <div class="searchDiv" xLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="0px" fxLayoutGap.xs="0">
        <span fxFlex="30%" fxFlexOrder="1"  class="title">Descarga de grabaciones</span>
        <div fxFlex="70%" fxFlexOrder="2" style="display: flex; justify-content: flex-end; align-items: center" >
            <form id="searchForm" [formGroup]="searchForm">
                <div class="container" style="margin-left: 30px;">
                    <label>Fecha</label>
                    <mat-form-field class="date" style="margin-left: 15px;" [hideRequiredMarker]="false">
                        <input matInput type="date" #date formControlName="date" useValueAsDate required>
                    </mat-form-field>
                    <mat-form-field style="margin-left: 15px;">
                        <mat-select placeholder="Medicos" #doctorId>
                            <mat-option></mat-option> 
                            <mat-option *ngFor="let doctor of doctors" [value]="doctor.id">{{doctor.lastName}} {{doctor.firstName}}</mat-option>
                        </mat-select>
                    </mat-form-field>                                                                                      
                    <button style="margin-right: 24px; margin-left: 30px"  mat-raised-button class="search-button" (click)="search(date.value,doctorId.value)" [disabled]="searchForm.invalid || loading">BUSCAR</button>
                </div>
                <div>
                    <!-- <button style="margin-left:30px" mat-raised-button class="back-button" type="button" (click)="onBackClick()">ATR�S</button>-->
                </div>
            </form>
        </div>
    </div>

    <div *ngIf="notFound" style="margin-left: 30px; margin-top:70px;">
        <span class="title">No se encontraron grabaciones</span>
    </div>

    <div *ngIf="loading"><img id="loader" src="./assets/images/loading.gif"></div>

    <div *ngIf="showRecordings">
        <section style="margin-left: 30px; margin-right: 30px; margin-top: 30px" class="main">
            <table >
                <thead>
                    <tr class="table-title">
                        <th width="25%">DOCTOR</th>
                        <th width="25%">PACIENTE</th>
                        <th width="12%">FECHA</th>
                        <th width="12%">DURACI�N</th>
                        <th width="12%">GRABACI�N M�DICO</th>
                        <th width="12%">GRABACI�N PACIENTE</th>
                        <th width="12%">COMPOSICI�N</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container>
                        <tr *ngFor="let recording of recordings | paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
                            <td width="25%">{{recording.doctor}}</td>
                            <td width="25%">{{recording.user}}</td>
                            <td width="12%">{{toDate(recording.dateCreated)}}</td>
                            <td width="12%">{{toMinutes(recording.duration)}}</td>
                        
                            <td width="12%">
                                <a href="{{recording.audioDoctor}}" style="text-decoration: none;">
                                    <img class="toolbar-title" src="./assets/images/audio.png" height="24" width="24" hspace="15">
                                </a>
                                <a href="{{recording.videoDoctor}}" style="text-decoration: none;">
                                    <img class="toolbar-title" src="./assets/images/video.png" height="24" width="24">
                                </a>
                            </td>
                            <td width="12%">
                                <a href="{{recording.audioUser}}" style="text-decoration: none;">
                                    <img class="toolbar-title" src="./assets/images/audio.png" height="24" width="24" hspace="15">
                                </a>
                                <a href="{{recording.videoUser}}" style="text-decoration: none;">
                                    <img class="toolbar-title" src="./assets/images/video.png" height="24" width="24">
                                </a>
                            </td>
                            <td width="12%" style="cursor: pointer;"><i (click)="createComposition(recording.roomSid)"class="material-icons">build</i></td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </section>
        <pagination-controls  *ngIf="recordings.length > 0" class="my-pagination" (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
    </div>
	<app-modal [useTwoButtons]='false' [modalTitle]="dialogTitle" [buttonTitle]="dialogButtonText" [blocking]='false' [modalId]='compositionDialogId'>
		<div>{{dialogText}}</div>
	</app-modal>

</body>

</html>

