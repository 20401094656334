<!DOCTYPE html>
<html style="height: 100vh">

<head>
	<title>Ingresar</title>
	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
	<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
	<!-- Include in <head> to load fonts from Google -->
		<link href="https://fonts.googleapis.com/css?family=Lato:100,500,300,200,400,700,900" rel="stylesheet">
		
</head>

<body style="height: 100vh">
	<div class="mainContainer" fxLayout fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px" fxLayoutGap.xs="0">
		<div class="leftContainer" fxFlex="65%" fxFlexOrder="1">
			<div class="image-container">
				<img src="./assets/images/home_logo.png">
			</div>
		</div>
		<div class="rightContainer" fxFlex="35%" fxFlexOrder="2">
			<div class="formContainer">
				<div style="margin-bottom: 50px">
					<label class="title">Iniciá sesión</label>
				</div>
				<form name="form" (ngSubmit)="f.form.valid && onSubmitClick()" #f="ngForm" novalidate>
					<mat-form-field [hideRequiredMarker]="true"  style="width: 300px">
						<input matInput type="email" [(ngModel)]="user.username" name="email" #email="ngModel" placeholder="Mail" id="email" required>
						<mat-error *ngIf="f.submitted && email.errors?.email && !email.errors?.required">El formato del email es incorrecto</mat-error>
						<mat-error *ngIf="f.submitted && email.errors?.required">Ingresá tu email</mat-error>
					</mat-form-field>
					<br>
					<mat-form-field [hideRequiredMarker]="true" style="width: 300px">
						<input matInput type="password" minlength="6" [(ngModel)]="user.password" name="password" #password="ngModel" placeholder="Contraseña"
						 id="Contraseña" required [type]="hide ? 'password' : 'text'">
						<mat-error *ngIf="f.submitted && password.errors?.minlength && !password.errors?.required">La contraseña debe tener al menos 6 caracteres</mat-error>
						<mat-error *ngIf="f.submitted && password.errors?.required">Ingresá tu contraseña</mat-error>
					</mat-form-field>
					<br>
					<div style="margin-top: 30px">
						<button class="loginButton" [disabled]="loading" mat-raised-button>INGRESAR</button>
					</div>
					<div style="margin-top: 20px">
						<mat-error>{{error}}</mat-error>
					</div>
				</form>

				<button class="forgotPassword" routerLink="/forgot-password">
					Olvidaste tu contraseña?
				</button>
			</div>
		</div>
	</div>
	<app-modal [useTwoButtons]='false' [modalTitle]="'Sesión expirada'" [buttonTitle]="'ACEPTAR'" [blocking]='false' [modalId]='sessionExpiredDialogId'>
		<div>Tu sesión expiró. Por favor, ingresá nuevamente.</div>
	</app-modal>
	<ng-progress></ng-progress>
</body>

</html>