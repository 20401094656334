import { Injectable, Injector } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map, tap } from "rxjs/operators";
import { User } from "./domain/User";
import { GeneralResponse } from "./domain/GeneralResponse";

import { UserInfo } from "./domain/UserInfo";
import { Affiliate } from "./domain/Affiliate";
import { environment } from "../environments/environment";
import { Router } from "@angular/router";
import { UserDTO } from "./domain/UserDTO";
import { Doctor } from "./domain/Doctor";
import { BaseService } from "./base.service";

@Injectable()
export class UserService extends BaseService {
  private authURL = `${environment.host}/auth`;
  private updatePasswordURL = `${environment.host}/user/password`;
  private createURL = `${environment.host}/user`;
  private resetPasswordURL = `${environment.host}/user/password/reset`;
  private getAffiliateListByGroupURL = `${environment.api}`;
  //private createDoctorURL = `${environment.host}/user`;
  private getDoctorListURL = `${environment.host}/doctor`;
  private logoutURL = `${environment.host}/doctor/logout`;
  private enableURL = `${environment.host}/user/enable`;
  private disableURL = `${environment.host}/user/disable`;
  private getAffiliatesURL = `${environment.api}/affiliate/`;

  private getUserByEmailURL = `${environment.host}/user/email/`;
  private createUserURL = `${environment.host}/user`;
  private updateURL = `${environment.host}/user`;

  private checkSifemeUserURL = `${environment.api2}/sifeme/check-user`;
  private createUserExtraDataURL = `${environment.api2}/user/extra-data`;

  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  login(user: User): Observable<UserInfo> {
    return this.http
      .post<UserInfo>(this.authURL, user, {})
      .pipe(catchError(this.handleError<UserInfo>("login")));
  }

  logout(): Observable<any> {
    return this.http
      .put<any>(this.logoutURL, {}, { headers: this.getHeaders() })
      .pipe(catchError(this.handleError<any>("logout")));
  }

  updatePassword(user: User): Observable<GeneralResponse> {
    return this.http
      .put<GeneralResponse>(this.updatePasswordURL, user, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError<GeneralResponse>("updatePassword")));
  }

  resetPassword(user: User): Observable<GeneralResponse> {
    return this.http
      .put<GeneralResponse>(this.resetPasswordURL, user, {})
      .pipe(catchError(this.handleError<GeneralResponse>("resetPassword")));
  }

  create(user: User): Observable<UserInfo> {
    return this.http
      .put<UserInfo>(this.createURL, user, { headers: this.getHeaders() })
      .pipe(catchError(this.handleError<UserInfo>("create")));
  }

  createV2(user: User): Observable<UserInfo> {
    return this.http
      .put<UserInfo>(this.createURL, user, { headers: this.getHeaders() })
      .pipe(catchError(this.handleError<UserInfo>("create")));
  }

  getAffiliateListByGroup(
    groupNumberId: number,
    providerId: number
  ): Observable<Affiliate[]> {
    return this.http
      .get<Affiliate[]>(
        `${this.getAffiliateListByGroupURL}/provider/${providerId}/group/${groupNumberId}`,
        {}
      )
      .pipe(
        catchError(this.handleError<Affiliate[]>("getAffiliateListByGroup"))
      );
  }

  createDoctor(userDTO: UserDTO): Observable<UserInfo> {
    return this.http
      .post<UserInfo>(this.createUserURL, userDTO, {})
      .pipe(catchError(this.handleError<UserInfo>("create")));
  }

  getDoctorList(): Observable<Doctor[]> {
    return this.http
      .get<Doctor[]>(this.getDoctorListURL, { headers: this.getHeaders() })
      .pipe(catchError(this.handleError<Doctor[]>("getDoctorListURL")));
  }

  getDoctorByFilter(filter: string): Observable<Doctor[]> {
    return this.http
      .get<Doctor[]>(`${this.getDoctorListURL}?filter=${filter}`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError<Doctor[]>("getDoctorListURL")));
  }

  enableUser(userId: number): Observable<GeneralResponse> {
    return this.http
      .post<GeneralResponse>(
        this.enableURL,
        { id: userId },
        { headers: this.getHeaders() }
      )
      .pipe(catchError(this.handleError<GeneralResponse>("Enable")));
  }

  disableUser(userId: number): Observable<GeneralResponse> {
    return this.http
      .post<GeneralResponse>(
        this.disableURL,
        { id: userId },
        { headers: this.getHeaders() }
      )
      .pipe(catchError(this.handleError<GeneralResponse>("Disable")));
  }

  getAffiliates(userId: number): Observable<Affiliate[]> {
    return this.http
      .get<Affiliate[]>(`${this.getAffiliatesURL}${userId}`, {})
      .pipe(catchError(this.handleError<Affiliate[]>("getAffiliates")));
  }

  createUser(userDTO: UserDTO): Observable<UserInfo> {
    return this.http
      .post<UserInfo>(this.createUserURL, userDTO, {})
      .pipe(catchError(this.handleError<UserInfo>("create")));
  }

  getUserByEmail(email: String): Observable<UserDTO> {
    return this.http
      .get<UserDTO>(`${this.getUserByEmailURL}${email}/`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError<UserDTO>("getUserByEmail")));
  }

  update(userDTO: UserDTO): Observable<UserDTO> {
    return this.http
      .put<UserDTO>(`${this.updateURL}`, userDTO, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError<UserDTO>("UpdateUser")));
  }

  checkIsSifemeUser(username: string): Observable<any> {
    return this.http.get<any>(
      `${this.checkSifemeUserURL}?username=${username}`,
      { headers: this.getHeaders() }
    );
  }

  createUserExtraData(userDTO: any): Observable<any> {
    return this.http.post<any>(this.createUserExtraDataURL, userDTO);
  }
}
