<!DOCTYPE html>
<html>
  <head>
    <title>Doctor En Casa</title>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
    />
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"
    />
    <!--script type="text/javascript"
	src="//media.twiliocdn.com/sdk/js/client/v1.4/twilio.min.js"></script -->
    <!-- Include in <head> to load fonts from Google -->
    <link
      href="https://fonts.googleapis.com/css?family=Lato:100italic"
      rel="stylesheet"
      type="text/css"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Lato:100"
      rel="stylesheet"
      type="text/css"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Lato:normal"
      rel="stylesheet"
      type="text/css"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Lato:300"
      rel="stylesheet"
      type="text/css"
    />
  </head>

  <body>
    <app-menu></app-menu>
    <div
      class="main-content"
      fxLayout="column"
      fxLayoutAlign="center"
      fxLayoutGap="0px"
    >
      <div
        class="main-top"
        fxFlexOrder="1"
        fxLayout
        fxLayout.xs="column"
        fxLayoutAlign="center"
        fxLayoutGap="0px"
        fxLayoutGap.xs="0"
      >
        <div
          class="main-left"
          fxFlex="40%"
          fxFlexOrder="1"
          fxLayoutAlign="center"
        >
          <div
            id="videocall-container"
            class="videocall-container"
            fxLayout="column"
            fxLayoutAlign="center"
            fxLayoutGap="0px"
          >
            <div
              id="video"
              #video
              [hidden]="!userConnected || (userConnected && trackDisabled)"
              fxFlex="100%"
              fxFlexOrder="1"
              style="width: 100%; align-self: center; position: absolute"
            >
              <!--video [hidden]="!callInProgress" id="incoming" #incoming class="incoming-video" autoplay></video>
						<video [hidden]="!callInProgress" id="outgoing" #outgoing style="display:none;height:0px" autoplay muted></video-->
            </div>
            <!--div id="localvideo" #localvideo [hidden]="!callInProgress" fxFlex="50%" fxFlexOrder="2" style="width: 30%;align-self: left"></div-->

            <div
              *ngIf="
                !callInProgress ||
                (callInProgress && !userConnected) ||
                (trackDisabled && userConnected)
              "
              class="image-container"
              fxFlex="60%"
              fxFlexOrder="1"
              style="margin-bottom: 30px"
            >
              <img
                src="./assets/images/home.png"
                style="width: 200px; height: 200px"
              />
            </div>
            <!--<div *ngIf="trackDisabled && userConnected" class="image-container" fxFlex="60%" fxFlexOrder="1" style="margin-bottom: 30px">
						<img src="./assets/images/cam_off.png" style="width: 200px; height: 200px;">
					</div> -->
            <!--<div class="button-container centered-horizontally-container"  style="z-index: 2;" fxFlex="40%" fxFlexOrder="2">-->
            <div
              class="button-container centered-horizontally-container"
              fxFlex="40%"
              fxFlexOrder="2"
              style="
                position: absolute;
                bottom: 5%;
                left: 0px;
                width: 40vw;
                height: 100px;
              "
            >
              <div
                fxLayout="column"
                fxLayoutAlign="center center"
                fxLayoutGap="0px"
                fxLayoutGap.xs="00px"
                style="
                  padding-right: 10px;
                  padding-left: 10px;
                  width: 100%;
                  padding-bottom: 60px;
                "
              >
                <!--span class="subtitle-no-margin" *ngIf="noMediaDevices" style="white-space: pre-wrap;">No fue posible encontrar una cámara web. Por favor, volvé a cargar la página o intentá reiniciando la computadora</span-->
                <span
                  class="subtitle-no-margin"
                  *ngIf="
                    noQueue &&
                    !disableCallButton &&
                    !currentVideocall &&
                    !noMediaDevices
                  "
                  >No hay consultas pendientes para atender.</span
                >
                <span class="subtitle-no-margin" *ngIf="connectingToRoom"
                  >Iniciando llamada ...</span
                >
                <span
                  class="subtitle-no-margin"
                  *ngIf="callInProgress && !userConnected"
                  >Esperando que el paciente se una a la videollamada ...</span
                >
                <button
                  class="call-button"
                  *ngIf="
                    !connectingToRoom &&
                    !callInProgress &&
                    (!noQueue || currentVideocall)
                  "
                  (click)="doCall()"
                  [disabled]="disableCallButton || noMediaDevices"
                  mat-raised-button
                  color="accent"
                >
                  {{ buttonText }}
                </button>

                <span
                  class="subtitle-no-margin"
                  *ngIf="
                    disableCallButton && currentVideocall && !noMediaDevices
                  "
                  style="margin-right: 10px; margin-left: 10px"
                  >Completá los datos de la videollamada antes de tomar una
                  nueva consulta.</span
                >
                <span
                  class="subtitle-no-margin"
                  [hidden]="
                    !(
                      !errorQueue &&
                      !callInProgress &&
                      !noMediaDevices &&
                      !disableCallButton &&
                      !activeRoom &&
                      !currentVideocall &&
                      !noQueue &&
                      queueCount > 1
                    )
                  "
                  >Hay {{ queueCount }} personas esperando para ser
                  atendidos.</span
                >
                <span
                  class="subtitle-no-margin"
                  [hidden]="
                    !(
                      !errorQueue &&
                      !callInProgress &&
                      !noMediaDevices &&
                      !disableCallButton &&
                      !activeRoom &&
                      !currentVideocall &&
                      !noQueue &&
                      queueCount == 1
                    )
                  "
                  >Hay 1 persona esperando para ser atendida.</span
                >
              </div>
              <div
                style="
                  padding-right: 10px;
                  padding-left: 10px;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  bottom: 60px;
                  position: absolute;
                "
              >
                <img
                  style="margin-right: 30px"
                  *ngIf="userConnected"
                  class="videocall-button"
                  (click)="doCall()"
                  src="./assets/images/ic_call_reject.png"
                />
                <img
                  *ngIf="userConnected && isMute"
                  class="videocall-button"
                  (click)="mute()"
                  src="./assets/images/ic_muted.png"
                />
                <img
                  *ngIf="userConnected && !isMute"
                  class="videocall-button"
                  (click)="mute()"
                  src="./assets/images/ic_mute.png"
                />
                <img
                  style="margin-left: 30px"
                  *ngIf="userConnected"
                  class="videocall-button"
                  (click)="fullscreen()"
                  src="./assets/images/ic_fullscreen.png"
                />
              </div>
            </div>
            <!-- button *ngIf="callInProgress && call != null" (click)="hangout()" mat-raised-button color="accent" style="color:white;margin-right: 14px">Cortar</button>
							<button *ngIf="currentVideocall && call == null" (click)="doCall()" mat-raised-button color="accent" style="color:white;margin-right: 14px">Llamar</button-->
          </div>
        </div>
        <div id="main-right" class="main-right" fxFlex="60%" fxFlexOrder="2">
          <div
            style="height: 100%"
            fxLayout="column"
            fxLayoutAlign="center"
            fxLayoutGap="0px"
          >
            <span class="subtitle-no-margin">Historia Clinica</span>
            <div
              style="height: 100%"
              fxLayout="column"
              fxLayoutAlign="top"
              fxLayoutGap="0px"
            >
              <form
                style="overflow: auto"
                fxFlex="100%"
                fxFlexOrder="1"
                name="form"
                (ngSubmit)="f.form.valid && onSubmitClick(f)"
                #f="ngForm"
                novalidate
              >
                <div
                  style="min-height: 100%; position: relative; overflow: auto"
                >
                  <mat-form-field
                    [hideRequiredMarker]="false"
                    style="margin-top: 20px; width: 100%"
                  >
                    <div *ngIf="this.newMedicalRecord.affiliateGamId">
                      <mat-select
                        id="affiliateCombobox"
                        name="affiliateCombobox"
                        #affiliateCombobox="ngModel"
                        placeholder="Seleccione un paciente"
                        [(ngModel)]="newMedicalRecord.affiliateGamId"
                        (selectionChange)="onAffiliateSelected()"
                        required
                      >
                        <mat-option
                          *ngFor="let affiliate of currentVideocallAffiliates"
                          [value]="affiliate.affiliateGamId"
                          >{{ affiliate.patient }}</mat-option
                        >
                      </mat-select>
                      <mat-error
                        *ngIf="
                          f.submitted && affiliateCombobox.errors?.required
                        "
                        >Selecciona un paciente</mat-error
                      >
                    </div>
                    <div *ngIf="!this.newMedicalRecord.affiliateGamId">
                      <!--<mat-select id="patientCombobox" name="patientCombobox" #patientCombobox="ngModel" placeholder="Seleccione un paciente"
										[(ngModel)]="newMedicalRecord.patient" required>
										   <mat-option *ngFor="let affiliate of currentVideocallAffiliates" [value]="affiliate.patient">{{affiliate.patient}}</mat-option>
									   </mat-select>-->
                      <input
                        matInput
                        [(ngModel)]="newMedicalRecord.patient"
                        name="patientCombobox"
                        #patientCombobox="ngModel"
                        placeholder="Paciente"
                        required
                      />
                    </div>
                  </mat-form-field>
                  <br />
                  <mat-form-field
                    [hideRequiredMarker]="false"
                    style="width: 100%"
                  >
                    <mat-select
                      id="reasonCombobox"
                      name="reasonCombobox"
                      #reasonCombobox="ngModel"
                      placeholder="Seleccione uno o más motivos"
                      multiple
                      [(ngModel)]="newMedicalRecord.reason"
                      required
                    >
                      <mat-option
                        *ngFor="let reason of reasonList"
                        [value]="reason.id"
                        >{{ reason.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="f.submitted && reasonCombobox.errors?.required"
                      >Selecciona al menos un motivo</mat-error
                    >
                  </mat-form-field>
                  <br />
                  <!--<mat-form-field [hideRequiredMarker]="true" style="width: 100%">
									<mat-chip-list #chipList>
										<mat-chip *ngFor="let interview of selectedInterviewList" [selectable]="true" [removable]="true" (removed)="remove(interview)">
											{{interview}}
											<mat-icon matChipRemove>cancel</mat-icon>
										</mat-chip>
										<input class="text-input" placeholder="Anamnesis" secondary-placeholder="Anamnesis" #interviewInput [formControl]="interviewCtrl" [matAutocomplete]="auto"
										 [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="false"
										 (matChipInputTokenEnd)="add($event)" />
									</mat-chip-list>
									<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
										<mat-option *ngFor="let interview of filteredInterviewList | async" [value]="interview.name">
											{{interview.name}}
										</mat-option>
									</mat-autocomplete>
								</mat-form-field> -->
                  <mat-form-field
                    [hideRequiredMarker]="false"
                    style="width: 100%; max-height: 100%"
                  >
                    <textarea
                      matInput
                      matTextareaAutosize
                      matAutosizeMinRows="1"
                      matAutosizeMaxRows="6"
                      type="text"
                      [(ngModel)]="newMedicalRecord.anamnesis"
                      name="anamnesis"
                      #anamnesis="ngModel"
                      placeholder="Anamnesis"
                      id="anamnesis"
                      required
                    ></textarea>
                    <mat-error *ngIf="f.submitted && anamnesis.errors?.required"
                      >Ingresá la anamnesis</mat-error
                    >
                  </mat-form-field>

                  <mat-form-field
                    [hideRequiredMarker]="false"
                    style="
                      padding-bottom: 10px;
                      width: 100%;
                      margin-bottom: 60px;
                      max-height: 100%;
                    "
                  >
                    <textarea
                      matInput
                      matTextareaAutosize
                      matAutosizeMinRows="1"
                      matAutosizeMaxRows="6"
                      type="text"
                      [(ngModel)]="newMedicalRecord.recommendation"
                      name="recommendation"
                      #recommendation="ngModel"
                      placeholder="Recomendaciones"
                      id="recommendation"
                      required
                    ></textarea>
                    <mat-error
                      *ngIf="f.submitted && recommendation.errors?.required"
                      >Ingresá tus recomendaciones</mat-error
                    >
                  </mat-form-field>

                  <div
                    style="
                      height: 60px;
                      position: absolute;
                      bottom: 0;
                      width: 100%;
                      margin-bottom: 10px;
                      margin-top: 30px;
                    "
                  >
                    <div
                      class="centered-horizontally-container"
                      style="text-align: center"
                    >
                      <button
                        class="send-button"
                        style="text-align: right"
                        [disabled]="hideSend"
                        mat-raised-button
                        style="background: #ebebee"
                      >
                        ENVIAR
                      </button>
                      <button
                        class="close-without-videocall-button"
                        style="text-align: right"
                        [disabled]="hideSend"
                        type="button"
                        mat-raised-button
                        style="background: #ebebee"
                        (click)="onSubmitWithoutVideocallClick(f)"
                      >
                        CERRAR SIN ATENCIÓN
                      </button>
                      <button
                        class="prescription-button"
                        style="text-align: right"
                        [disabled]="hidePrescription"
                        type="button"
                        mat-raised-button
                        style="background: #ebebee"
                        (click)="openDialogReceta()"
                      >
                        RECETA
                      </button>
                      <!--<button style="margin-left:30px" mat-raised-button class="cancel-button" type="button" (click)="onCancelClick()">CANCELAR</button>  [disabled]="hidePrescription"-->
                    </div>

                    <div
                      class="centered-horizontally-container"
                      style="text-align: left"
                    >
                      <span
                        class="text-info"
                        style="margin-right: 5px; text-align: right"
                        >*Campo obligatorio</span
                      >
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        class="main-bottom"
        fxFlexOrder="2"
        fxLayout="column"
        fxLayoutAlign="center"
        fxLayoutGap="0px"
        fxLayoutGap.xs="10px"
      >
        <div fxFlex="10%" fxFlexOrder="1" fxLayoutAlign="left">
          <span
            *ngIf="
              !isMedicalRecordListLoading &&
              currentVideocall &&
              currentVideocallAffiliates
            "
            class="subtitle-no-margin"
            >Consultas Anteriores</span
          >
          <span
            *ngIf="
              (this.newMedicalRecord.affiliateGamId &&
                this.visibleMedicalRecordList &&
                this.visibleMedicalRecordList[0]) ||
              (!this.newMedicalRecord.affiliateGamId &&
                this.visibleMedicalRecordList &&
                this.visibleMedicalRecordList[0])
            "
            class="subtitle-no-margin"
            >&nbsp;de {{ this.visibleMedicalRecordList[0].patient }}</span
          >
          <span class="subtitle-no-margin" *ngIf="isMedicalRecordListLoading"
            >Obteniendo la historia médica del paciente ...</span
          >
        </div>
        <div fxFlex="70%" fxFlexOrder="2" style="padding-top: 10px">
          <span
            class="empty-text"
            *ngIf="
              currentVideocall &&
              !isMedicalRecordListLoading &&
              this.newMedicalRecord.affiliateGamId &&
              (!this.visibleMedicalRecordList ||
                !this.visibleMedicalRecordList[0])
            "
            >El paciente no tiene consultas previas.</span
          >
          <mat-list>
            <div
              mat-list-item
              *ngFor="let medicalRecord of visibleMedicalRecordList"
            >
              <div
                style="
                  border-bottom: solid #dfdcdc 0.5px;
                  padding-top: 20px;
                  padding-bottom: 20px;
                  align-items: left;
                "
                fxLayout="column"
                fxLayoutAlign="left"
                fxLayoutGap="10px"
              >
                <div fxFlexOrder="1">
                  <span mat-line>{{
                    toDate(medicalRecord.videocall.date)
                  }}</span>
                </div>
                <div fxFlexOrder="2">
                  <span mat-line style="color: rgb(92, 90, 90)"
                    >Atendido por:</span
                  >
                  <span mat-line>
                    {{ medicalRecord.videocall.doctor.firstName }}
                    {{ medicalRecord.videocall.doctor.lastName }}</span
                  >
                </div>
                <div fxFlexOrder="3">
                  <span mat-line style="color: rgb(92, 90, 90)">Motivos:</span>
                  <span mat-line>
                    {{ splitReasons(medicalRecord.reasons) }}</span
                  >
                </div>
                <div fxFlexOrder="5">
                  <span mat-line style="color: rgb(92, 90, 90)"
                    >Anamnesis:</span
                  >
                  <span mat-line> {{ medicalRecord.anamnesis }}</span>
                </div>
                <div fxFlexOrder="6">
                  <span mat-line style="color: rgb(92, 90, 90)"
                    >Recomendaciones:</span
                  >
                  <span mat-line> {{ medicalRecord.recommendation }}</span>
                </div>
              </div>
            </div>
          </mat-list>
        </div>
      </div>
    </div>
    <app-modal
      [useTwoButtons]="false"
      [modalTitle]="'Consulta finalizada'"
      [buttonTitle]="'ACEPTAR'"
      [blocking]="false"
      [modalId]="noResponseDialogId"
    >
      <div>El paciente no se conectó a la videollamada.</div>
    </app-modal>
    <app-modal
      [useTwoButtons]="false"
      [modalTitle]="dialogTitle"
      [buttonTitle]="dialogButtonText"
      [blocking]="false"
      [modalId]="commonDialogId"
    >
      <div>{{ dialogText }}</div>
    </app-modal>
    <app-modal
      [useTwoButtons]="true"
      [modalTitle]="'Llamada finalizada'"
      [buttonTitle]="'Volver a llamar'"
      [button2Title]="'Finalizar'"
      [blocking]="false"
      [modalId]="retryDialogId"
    >
      <div>
        La llamada ha finalizado. Si la consulta no había terminado, podés
        volver a llamar.
      </div>
    </app-modal>
    <ng-progress></ng-progress>
  </body>
</html>
