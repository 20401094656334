import { PrescriptionItem } from "./PrescriptionItem";

export class PrescriptionDTO {
    patientName: string;
    documentNumber: number;
    medicalCoverage: string;
    planName: string;
    memberId: string;
    diagnostic: string;
    archived: boolean = false;
    businessName: string;
    cuit: string;
    address: string;
    technicalDirector: string;
    technicalDirectorRegistration: string;
    videocallId: number;
    items: PrescriptionItem[];
}