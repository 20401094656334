import { Component, OnInit } from "@angular/core";
import { User } from "../domain/User";
import { Router } from "@angular/router";
import { UserService } from "../user.service";
import { NgForm } from "@angular/forms";
import { ViewChild } from "@angular/core";
import { ModalService } from "../home/modal.service";
import { Subscription } from "rxjs";
import { Session } from "../util/Session";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  user: User = new User();
  loading = false;
  error = "";
  hide = true;
  sessionExpiredDialogId = "sessionExpiredDialogId";
  subscription: Subscription = null;
  homePath: string;

  @ViewChild("f", { static: true }) loginForm: HTMLFormElement;

  constructor(
    private router: Router,
    private userService: UserService,
    public modalService: ModalService
  ) {}

  ngOnInit() {
    if (localStorage.getItem("currentUser")) {
      // logged in so return true
      this.router.navigate(["/home"]);
    }
  }

  ngAfterViewInit() {
    let loadingElement = document.querySelector("#loading");
    if (loadingElement) {
      loadingElement.remove();
    }
    if (Session.isSessionExpired()) {
      localStorage.setItem("sessionExpired", "false");
    }
  }

  onSubmitClick(): void {
    this.error = "";
    this.loading = true;
    this.userService.login(this.user).subscribe((userInfo) => {
      if (userInfo && userInfo.token) {
        Session.login(userInfo);
        this.userService
          .checkIsSifemeUser(this.user.username)
          .subscribe((isSifemeUser) => {
            console.log('isSifemeUser login', isSifemeUser.data)
            localStorage.removeItem("sifemeUserCheck");
            localStorage.setItem("sifemeUserCheck",  isSifemeUser.data);
            if(Session.isSifemeUser()){
              this.router.navigateByUrl('/affiliate');
            }
            this.router.navigateByUrl(Session.getHomePath());
          });
      } else {
        this.loading = false;
        this.error = "Usuario o contraseña incorrectos";
      }
    });
  }
}
