<!DOCTYPE html>
<html>

<head>
	<title>Recuperar contraseña</title>
	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
	<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
	<!-- Include in <head> to load fonts from Google -->
	<link href='https://fonts.googleapis.com/css?family=Lato:100italic' rel='stylesheet' type='text/css'>
	<link href='https://fonts.googleapis.com/css?family=Lato:normal' rel='stylesheet' type='text/css'>
	<link href='https://fonts.googleapis.com/css?family=Lato:300' rel='stylesheet' type='text/css'>
</head>

<body>
	<app-menu></app-menu>

	<div class="searchDiv" xLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="0px" fxLayoutGap.xs="0">
		<div fxFlex="100%" fxFlexOrder="3" style="display: flex; justify-content: flex-end; align-items: center">
			<input style="float: right; margin-right: 20px; margin-top: 10px;" class="searchInput" placeholder="Buscar por email.." 
       name="documentNumber" #searchInput>
			<button [disabled]="loading" mat-raised-button class="button" (click)="search(searchInput.value)" style="float: right; margin-right: 5px;">BUSCAR</button>
			<button [disabled]="loading" mat-raised-button class="button" (click)="newUser()">NUEVO</button>
		</div>
	</div>


	<div fxLayout="column" fxLayoutGap="0px" fxLayoutGap.xs="10px" style="width: 100%; height: 90vh;" class="main-container">
		<div fxFlexOrder="1" fxFlex="20%">
			<legend class="title" style="margin-top:50px;">Nuevo Usuario</legend>
		</div>

		<div fxFlexOrder="2" fxFlex="70%" class="container" style="margin-left: 30px; margin-top: 30px;">

			<form name="form" (ngSubmit)="f.form.valid && onSubmitClick()" #f="ngForm" novalidate>
				<div style="text-align: center">

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput [(ngModel)]="userDTO.firstName" name="firstName" placeholder="Nombre" id="firstName">
						<mat-error>Ingresá Nombre</mat-error>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput [(ngModel)]="userDTO.lastName" name="lastName" placeholder="Apellido" id="lastName">
						<mat-error>Ingresá Apellido</mat-error>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false" *ngIf="this.isNewUser">
						<input matInput minlength="6" [(ngModel)]="userDTO.password" name="password" #password="ngModel" placeholder="Contraseña"
						 [type]="!hide ? 'password' : 'text'" id="password" required>
						<mat-error *ngIf="f.submitted && password.errors?.minlength && !password.errors?.required">La contraseña debe
							tener al menos 6 caracteres</mat-error>
						<mat-error *ngIf="f.submitted && password.errors?.required">Ingresá contraseña</mat-error>
						<mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false" *ngIf="this.isNewUser">
						<input matInput [(ngModel)]="confirmPasswordVar" name="passwordConfirm" #passwordConfirm="ngModel" placeholder="Confirmar contraseña"
						 [type]="!hide ? 'password' : 'text'" id="passwordConfirm" minlength="6" required>
						<mat-error *ngIf="f.submitted && passwordConfirm.errors?.minlength && !passwordConfirm.errors?.required">La
							contraseña debe tener al menos 6 caracteres</mat-error>
						<mat-error *ngIf="f.submitted && passwordConfirm.errors?.required">Repetí la contraseña</mat-error>
						<mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput type="email" [(ngModel)]="userDTO.email" name="email" #email="ngModel" placeholder="Email" id="email"
						 required email>
						<mat-error *ngIf="f.submitted && email.errors?.email && !email.errors?.required">El formato del email es
							incorrecto</mat-error>
						<mat-error *ngIf="f.submitted && email.errors?.required">Ingresá tu email</mat-error>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput type="number" [(ngModel)]="userDTO.documentNumber" name="documentNumber" placeholder="N° Documento"
						 id="documentNumber">
						 <mat-error>Ingresá número de documento</mat-error>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput type="number" [(ngModel)]="userDTO.telephone" name="telephone" placeholder="Teléfono" id="telephone" required>
						<mat-error>Ingresá Teléfono</mat-error>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput type="number" [(ngModel)]="userDTO.age" name="age" placeholder="Edad" id="age">
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<mat-select id="sexCombobox" name="sexCombobox" #sexCombobox="ngModel" placeholder="Genero" selectedValue
						 [(ngModel)]="userDTO.sex">
							<mat-option value="M">Hombre</mat-option>
							<mat-option value="F">Mujer</mat-option>
						</mat-select>
						<mat-error>Selecciona un género</mat-error>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput type="number" [(ngModel)]="userDTO.nationalEnrollment" name="nationalEnrollment" placeholder="Matricula"
						 id="nationalEnrollment">
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<mat-select id="roleCombobox" name="roleCombobox" #roleCombobox="ngModel" placeholder="Rol" selectedValue
						 [(ngModel)]="userDTO.role" [disabled]="!this.isNewUser" required>
							<mat-option *ngIf="isAdmin" value="ROLE_ADMIN">Administrador</mat-option>
							<mat-option value="ROLE_DOCTOR">Médico</mat-option>
							<mat-option value="ROLE_OPERATOR">Operador</mat-option>
						</mat-select>
						<mat-error>Selecciona rol</mat-error>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<mat-select id="specialityCombobox" name="specialityCombobox" #specialityCombobox="ngModel" placeholder="Seleccione una o más especialidades"
							multiple [(ngModel)]="userDTO.specialityList" required>
								<mat-option *ngFor="let speciality of specialityList" [value]="speciality.id">{{speciality.name}} </mat-option>
						</mat-select>
						<mat-error *ngIf="f.submitted && specialityCombobox.errors?.required">Selecciona al menos una especialidad</mat-error>
					</mat-form-field>

					<mat-checkbox class="sifeme" [(ngModel)]="userDTO.sifeme" name="sifeme"> Usuario de Sifeme </mat-checkbox>

				</div>
				<br>
				<div style="margin-top:30px; text-align: center">
					<mat-error>{{error}}</mat-error>
					<br />
					<button [disabled]="loading" *ngIf="this.isNewUser" mat-raised-button class="submit-button" style="margin-left:30px;">GUARDAR</button>
					<button [disabled]="loading" *ngIf="!this.isNewUser" mat-raised-button class="submit-button" style="margin-left:30px;">ACTUALIZAR</button>
					<button style="margin-left:30px" mat-raised-button class="cancel-button" type="button" (click)="onCancelClick()">CANCELAR</button>
				</div>
			</form>

		</div>
	</div>
	<ng-progress></ng-progress>

	<app-modal [useTwoButtons]='false' [modalTitle]="dialogTitle" [buttonTitle]="dialogButtonText" [blocking]='false'
	 [modalId]='commonDialogId'>
		<div>{{dialogText}}</div>
	</app-modal>

</body>

</html>
