import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Videocall } from './domain/Videocall';
import { environment } from '../environments/environment';
import { BaseService } from './base.service';


@Injectable()
export class VideocallService extends BaseService {

	private nextCallURL = `${environment.host}/videocall/next`;
	private finishURL = `${environment.host}/videocall/finish`;
	private startURL = `${environment.host}/videocall/start`;
	private cancelURL = `${environment.host}/videocall/cancel`;
	private missedURL = `${environment.host}/videocall/missed`;
	private dismissedURL = `${environment.host}/videocall/dismissed`;
	private currentURL = `${environment.host}/videocall/current`;
	private queueURL = `${environment.host}/videocall/queue`;
	private callURL = `${environment.host}/call`;
	private inProgressURL = `${environment.host}/videocall/inprogress`;
	private auditorTokenURL = `${environment.host}/audit/videocall`;
	private finishWithoutVideocallURL = `${environment.host}/videocall/finish/missed`;

	constructor(private injector: Injector, private http: HttpClient) {
		super(injector);
	}

	queue(): Observable<number> {
		return this.http.get<number>(this.queueURL, { headers: this.getHeaders() })
			.pipe(
				catchError(this.handleError<number>('queue'))
			);
	}


	current(): Observable<Videocall> {
		return this.http.get<Videocall>(this.currentURL, { headers: this.getHeaders() })
			.pipe(
				catchError(this.handleError<Videocall>('current'))
			);
	}


	nextCall(): Observable<Videocall> {
		return this.http.post<Videocall>(this.nextCallURL, {}, { headers: this.getHeaders() })
			.pipe(
				catchError(this.handleError<Videocall>('nextCall'))
			);
	}

	call(videocallId: number): Observable<Videocall> {
		return this.http.post<Videocall>(this.callURL, { "videocallId": videocallId }, { headers: this.getHeaders() })
			.pipe(
				catchError(this.handleError<Videocall>('call'))
			);
	}

	finish(videocallId: number): Observable<Videocall> {
		return this.http.post<Videocall>(this.finishURL, { "videocallId": videocallId }, { headers: this.getHeaders() })
			.pipe(
				catchError(this.handleError<Videocall>('finish'))
			);
	}

	start(videocallId: number, roomId: String): Observable<Videocall> {
		return this.http.post<Videocall>(this.startURL, { "videocallId": videocallId, "roomId": roomId }, { headers: this.getHeaders() })
			.pipe(
				catchError(this.handleError<Videocall>('start'))
			);
	}

	cancel(videocallId: number): Observable<Videocall> {
		return this.http.post<Videocall>(this.cancelURL, { "videocallId": videocallId }, { headers: this.getHeaders() })
			.pipe(
				catchError(this.handleError<Videocall>('cancel'))
			);
	}

	missed(videocallId: number): Observable<Videocall> {
		return this.http.post<Videocall>(this.missedURL, { "videocallId": videocallId }, { headers: this.getHeaders() })
			.pipe(
				catchError(this.handleError<Videocall>('missed'))
			);
	}

	dismissed(videocallId: number): Observable<Videocall> {
		return this.http.post<Videocall>(this.dismissedURL, { "videocallId": videocallId }, { headers: this.getHeaders() })
			.pipe(
				catchError(this.handleError<Videocall>('dismissed'))
			);
	}

	inProgress(): Observable<Videocall[]> {
		return this.http.get<Videocall[]>(this.inProgressURL, { headers: this.getHeaders() })
			.pipe(
				catchError(this.handleError<Videocall[]>('in progress'))
			);
	}

	getAuditorToken(videocallId: number): Observable<Videocall> {
		return this.http.get<Videocall>(`${this.auditorTokenURL}/${videocallId}`, { headers: this.getHeaders() })
			.pipe(
				catchError(this.handleError<Videocall>('auditor token'))
			);
	}

	finishWithoutVideocall(videocallId: number): Observable<Videocall> {
		return this.http.post<Videocall>(this.finishWithoutVideocallURL, { "videocallId": videocallId }, { headers: this.getHeaders() })
			.pipe(
				catchError(this.handleError<Videocall>('finishWithoutVideocallURL'))
			);
	}

}
