<!DOCTYPE html>
<html>

<head>
	<title>Recuperar contraseña</title>
	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
	<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
	<!-- Include in <head> to load fonts from Google -->
	<link href='https://fonts.googleapis.com/css?family=Lato:100italic' rel='stylesheet' type='text/css'>
	<link href='https://fonts.googleapis.com/css?family=Lato:normal' rel='stylesheet' type='text/css'>
	<link href='https://fonts.googleapis.com/css?family=Lato:300' rel='stylesheet' type='text/css'>
</head>

<body>
	<app-menu></app-menu>
	<div fxLayout="column" fxLayoutGap="0px" fxLayoutGap.xs="10px" style="width: 100%; height: 90vh;" class="main-container">
		<div fxFlexOrder="1" fxFlex="20%">
			<legend class="title" style="margin-top:50px;">Nuevo Médico</legend>
		</div>

		<div fxFlexOrder="2" fxFlex="70%" class="container" style="margin-left: 30px; margin-top: 30px;">

			<form name="form" (ngSubmit)="f.form.valid && onSubmitClick()" #f="ngForm" novalidate>
				<div style="text-align: center">

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput [(ngModel)]="userDTO.firstName" name="firstName" placeholder="Nombre" id="firstName" required>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput [(ngModel)]="userDTO.lastName" name="lastName" placeholder="Apellido" id="lastName" required>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput minlength="6" [(ngModel)]="userDTO.password" name="password" #password="ngModel" placeholder="Contraseña"
						 [type]="!hide ? 'password' : 'text'" id="password" required>
						<mat-error *ngIf="f.submitted && password.errors?.minlength && !password.errors?.required">La contraseña debe
							tener al menos 6 caracteres</mat-error>
						<mat-error *ngIf="f.submitted && password.errors?.required">Ingresá contraseña</mat-error>
						<mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput [(ngModel)]="confirmPasswordVar" name="passwordConfirm" #passwordConfirm="ngModel" placeholder="Confirmar contraseña"
						 [type]="!hide ? 'password' : 'text'" id="passwordConfirm" minlength="6" required>
						<mat-error *ngIf="f.submitted && passwordConfirm.errors?.minlength && !passwordConfirm.errors?.required">La
							contraseña debe tener al menos 6 caracteres</mat-error>
						<mat-error *ngIf="f.submitted && passwordConfirm.errors?.required">Repetí la contraseña</mat-error>
						<mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput type="email" [(ngModel)]="userDTO.email" name="email" #email="ngModel" placeholder="Email" id="email"
						 required email>
						<mat-error *ngIf="f.submitted && email.errors?.email && !email.errors?.required">El formato del email es
							incorrecto</mat-error>
						<mat-error *ngIf="f.submitted && email.errors?.required">Ingresá tu email</mat-error>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput type="number" [(ngModel)]="userDTO.documentNumber" name="documentNumber" placeholder="N° Documento"
						 id="documentNumber" required>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput type="number" [(ngModel)]="userDTO.age" name="age" placeholder="Edad" id="age">
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<mat-select id="sexCombobox" name="sexCombobox" #sexCombobox="ngModel" placeholder="Genero" selectedValue
						 [(ngModel)]="userDTO.sex" required>
							<mat-option value="M">Hombre</mat-option>
							<mat-option value="F">Mujer</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<input matInput type="number" [(ngModel)]="userDTO.nationalEnrollment" name="nationalEnrollment" placeholder="Matricula"
						 id="nationalEnrollment">
					</mat-form-field>

					<mat-form-field [hideRequiredMarker]="false">
						<mat-select id="roleCombobox" name="roleCombobox" #roleCombobox="ngModel" placeholder="Rol" selectedValue
						 [(ngModel)]="userDTO.administrator" required>
							<mat-option value="true">Administrador</mat-option>
							<mat-option value="false">Médico</mat-option>
						</mat-select>
					</mat-form-field>

				</div>
				<br>
				<div style="margin-top:30px; text-align: center">
					<button [disabled]="loading" mat-raised-button class="save-button" style="margin-left:30px;">GUARDAR</button>
					<button style="margin-left:30px" mat-raised-button class="cancel-button" type="button" (click)="onCancelClick()">CANCELAR</button>
				</div>
			</form>

		</div>
		<div fxFlex="10%" fxFlexOrder="2" style="margin-top: 20px">
			<mat-error>{{error}}</mat-error>
		</div>
	</div>
	<ng-progress></ng-progress>
</body>

</html>