import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Provider } from '@angular/compiler/src/core';
import { UserDTO } from '../../domain/UserDTO';
import { UserService } from '../../user.service';
import { UserInfo } from '../../domain/UserInfo';
import { Router } from '@angular/router';

@Component({
  selector: 'app-adddoctor',
  templateUrl: './adddoctor.component.html',
  styleUrls: ['./adddoctor.component.css']
})
export class AdddoctorComponent implements OnInit {

  providerList: Provider[];
  hide : boolean;
  userDTO: UserDTO = new UserDTO();
  loading = false;
  error: string;
  confirmPasswordVar: string;

  constructor(private router: Router,private userService: UserService, private location: Location) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    let loadingElement = document.querySelector('#loading');
    if (loadingElement) {
      loadingElement.remove();
    }

  }

  onSubmitClick(): void {
    this.error = '';
    this.loading = true;
    this.userDTO.username = this.userDTO.email;
    if (this.userDTO.password === this.confirmPasswordVar) {
      this.userService.createDoctor(this.userDTO)
        .subscribe(userInfo => {
          if (userInfo != null) {
            this.router.navigateByUrl('/doctor');
          } else {
            this.loading = false;
            this.error = "Error al guardar el Medico";
          }
        }
        );
    } else {
      this.error = 'Las contraseñas no coinciden';
      this.loading = false;
    }
  }

  onCancelClick(): void {
    this.location.back();
  }

}