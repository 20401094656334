import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PrescriptionDTO } from '../domain/PrescriptionDTO';
import { Prescription } from '../domain/Prescription';
import { PrescriptionService } from '../prescription.service';
import { Observable } from 'rxjs';
import { scan } from 'rxjs/operators';
import { PrescriptionItem } from '../domain/PrescriptionItem';

@Component({
  selector: 'app-popup-prescription',
  templateUrl: './popup-prescription.component.html',
  styleUrls: ['./popup-prescription.component.css'],
})
export class PopupPrescriptionComponent implements OnInit {

  @ViewChild('textBoxDrug') textBoxDrug: ElementRef;

  prescriptionDTO: PrescriptionDTO = new PrescriptionDTO();
  prescriptions: Prescription[];
  newPrescription: boolean = false;
  isEmpty: boolean = true;
  items: PrescriptionItem[];
  item: PrescriptionItem;
  archived: boolean = false;
  error: string;


  constructor(private dialogRef: MatDialogRef<PopupPrescriptionComponent>, private prescriptionService: PrescriptionService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit() {
    this.getPrescriptions();
    this.items = [];
    this.item = new PrescriptionItem();
  }

  closeWithoutSave() {
    //this.logService.print('Closed without save', LogService.DEFAULT_MSG);
    this.dialogRef.close();
  }

  getPrescriptions() {
    //TODO ID de consulta
    this.prescriptionService.getPrescriptionList(this.data.id).subscribe(
      prescriptions => {
        this.prescriptions = prescriptions;
        if (prescriptions) {
          this.isEmpty = false;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  onSubmitClick() {

    if (this.items.length === 0) {
      console.log("Error al guardar")
      this.error = "Error al guardar la receta.";
    } else {
      this.prescriptionDTO.items = this.items;
      this.prescriptionDTO.archived = this.archived;
      this.prescriptionDTO.videocallId = this.data.id;
      console.log(this.prescriptionDTO);
      this.prescriptionService.save(this.prescriptionDTO)
        .subscribe(generalResponse => {
          if (generalResponse) {
            console.log("Se guardo")
            console.log(this.prescriptionDTO);
            this.onCancelClick();
          } else {
            console.log("Error al guardar")
            this.error = "Error al guardar la receta.";
          }
        }
        );
    }

  }

  onAddClick() {
    this.newPrescription = true;
  }

  onCancelClick() {
    this.getPrescriptions();
    this.newPrescription = false;
    this.items = [];
    this.prescriptionDTO = new PrescriptionDTO();
    this.error = '';
  }

  onAddItemClick(quantity: number, drug: string) {

    if (!this.isNull(drug)) {
      this.item = new PrescriptionItem();

      this.item.drug = drug;
      this.item.quantity = quantity;

      this.items.push(this.item);

      this.textBoxDrug.nativeElement.value = '';

      console.log(this.items);
    }
  }

  onDeleteItemClick(index: number) {
    this.items.splice(index, 1);
    console.log(this.items);
  }

  onArchivedClick() {

    if (this.prescriptionDTO.archived) {
      this.archived = false;
    } else {
      this.archived = true;
    }
    
  }

  delete(prescriptionId: number) {
    console.log(prescriptionId);
      this.prescriptionService.delete(prescriptionId).subscribe(
        generalResponse => {
          if (generalResponse) {
            console.log("Se elimino");
            this.getPrescriptions();
          }
        },
        err => {
          console.log(err);
        }
      );
    }


  isNull(str) {
    return (!str || 0 === str.length);
  }  

}
