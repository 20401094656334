
<mat-dialog-content>

  <!--style="width: 60vh; height: 40vh;" -->
  <div fxLayout="column" fxLayoutGap="0px" fxLayoutGap.xs="10px" style="width: 60vh;" class="main-container">
    <div fxFlexOrder="1" fxFlex="20%">
      <legend class="title" style="margin-bottom:10px;">Receta Digital</legend>
    </div>

    <!--<div *ngIf="newPrescription" fxFlexOrder="2" fxFlex="70%" class="container" style="margin-left: 30px; margin-top: 30px;">-->
    <div *ngIf="newPrescription" class="container" style="width: 100%" fxFlexOrder="3" fxFlex="70%" class="container">
      <form name="form" (ngSubmit)="f.form.valid && onSubmitClick()" #f="ngForm" novalidate>

        <mat-form-field [hideRequiredMarker]="false">
          <input matInput [(ngModel)]="prescriptionDTO.patientName" name="patientName" placeholder="Nombre completo" id="patientName"
            required>
          <mat-error>Ingresá nombre</mat-error>
        </mat-form-field>

        <mat-form-field [hideRequiredMarker]="false">
          <input matInput [(ngModel)]="prescriptionDTO.documentNumber" name="documentNumber" placeholder="DNI" id="documentNumber"
            required>
          <mat-error>Ingresá DNI</mat-error>
        </mat-form-field>

        <mat-form-field [hideRequiredMarker]="false">
          <input matInput [(ngModel)]="prescriptionDTO.medicalCoverage" name="medicalCoverage" placeholder="Obra Social"
            id="medicalCoverage" required>
          <mat-error>Ingresá obra social</mat-error>
        </mat-form-field>

        <mat-form-field [hideRequiredMarker]="false">
          <input matInput [(ngModel)]="prescriptionDTO.planName" name="planName" placeholder="Plan" id="planName"
            required>
          <mat-error>Ingresá plan</mat-error>
        </mat-form-field>

        <mat-form-field [hideRequiredMarker]="false">
          <input matInput [(ngModel)]="prescriptionDTO.memberId" name="memberId" placeholder="N° Afiliado" id="memberId"
            required>
          <mat-error>Ingresá n° afiliado</mat-error>
        </mat-form-field>

        <div>
          <span class="title" style="margin-left: 20px; margin-top:10px;">Medicamentos</span>
          <li class="text-info" style="margin:8px" *ngFor="let prescriptionItem of items; index as i">
            {{prescriptionItem.drug}} x {{prescriptionItem.quantity}}  <button class="add-button" type="button" (click)="onDeleteItemClick(i)">-</button>
          </li>
        </div>

        <div style="margin-left: 20px; margin-top: 30px; margin-right: 20px">
          <input class="textBoxQuantity" type="number" value="1" #textBoxQuantity required>
          <input class="textBoxDrug" type="text" placeholder="Medicamento" #textBoxDrug required>
          <button class="add-button" type="button" (click)="onAddItemClick(textBoxQuantity.value, textBoxDrug.value)">+</button>
        </div>

        <div class="divDrug">
          <mat-form-field [hideRequiredMarker]="false" style="margin-left: 20px; ">
            <textarea matInput matTextareaAutosize matAutosizeMinRows="1" matAutosizeMaxRows="6" type="text"
              [(ngModel)]="prescriptionDTO.diagnostic" name="diagnostic" placeholder="Diagnostico" id="diagnostic"></textarea>
          </mat-form-field>

          <mat-checkbox [(ngModel)]="prescriptionDTO.archived" name="archived" (click)="onArchivedClick()" class="archived">Archivada</mat-checkbox>
        </div>
        <div *ngIf="archived" style="margin-top: 20px">

          <mat-form-field [hideRequiredMarker]="false">
            <input matInput [(ngModel)]="prescriptionDTO.businessName" name="businessName" placeholder="Razón social"
              id="businessName" required>
            <mat-error>Ingresá razón social</mat-error>
          </mat-form-field>

          <mat-form-field [hideRequiredMarker]="false">
            <input matInput [(ngModel)]="prescriptionDTO.cuit" name="cuit" placeholder="Cuit" id="cuit" required>
            <mat-error>Ingresá cuit</mat-error>
          </mat-form-field>

          <mat-form-field [hideRequiredMarker]="false">
            <input matInput [(ngModel)]="prescriptionDTO.address" name="address" placeholder="Dirección" id="address"
              required>
            <mat-error>Ingresá la dirección</mat-error>
          </mat-form-field>

          <mat-form-field [hideRequiredMarker]="false">
            <input matInput [(ngModel)]="prescriptionDTO.technicalDirector" name="technicalDirector" placeholder="Director técnico"
              id="technicalDirector" required>
            <mat-error>Ingresá director técnico</mat-error>
          </mat-form-field>

          <mat-form-field [hideRequiredMarker]="false">
            <input matInput [(ngModel)]="prescriptionDTO.technicalDirectorRegistration" name="technicalDirectorRegistration"
              placeholder="Matrícula" id="technicalDirectorRegistration" required>
            <mat-error>Ingresá matrícula</mat-error>
          </mat-form-field>

        </div>

        <br>
        <div style="margin-top:30px; text-align: center">
          <button [disabled]="loading" mat-raised-button class="submit-button">Guardar</button>
          <button mat-raised-button class="cancel-button" type="button" (click)="onCancelClick()">Cancelar</button>
        </div>

      </form>


    </div>

    <div *ngIf="!newPrescription" class="container" style="width: 100%" fxFlexOrder="3" fxFlex="70%" class="container">

      <div *ngIf="isEmpty" style="margin-left: 30px; margin-top:70px;">
        <span class="title">Todavia no cargaste ninguna receta</span>
      </div>
      <div *ngIf="!isEmpty" class="row" style="margin: 30px; ">
        <div class="col">
          <section class="main">
            <table class="table table-bordered table-striped tabla">
              <thead>
                <tr class="table-title">
                  <th>Paciente</th>
                  <th>DNI</th>
                  <th>Obra Social</th>
                  <th>Rp</th>
                  <th>Eliminar</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let prescription of prescriptions">
                  <td>{{prescription.patientName}}</td>
                  <td>{{prescription.documentNumber}}</td>
                  <td>{{prescription.medicalCoverage}}</td>
                  <td>
                <tr *ngFor="let item of prescription.items">
                  <td>{{item.quantity}} x {{item.drug}}</td>
                  </td>
                  <td style="cursor: pointer;"><i (click)="delete(prescription.id)" class="material-icons">delete</i>
                  </td>

                </tr>
              </tbody>
            </table>
          </section>
        </div>
      </div>

    </div>

    <br>
    <div *ngIf="!newPrescription" style="margin-top:30px; text-align: left" fxFlexOrder="4" fxFlex="70%" class="container">
      <button *ngIf="!newPrescription" mat-raised-button class="submit-button" type="button" (click)="onAddClick()">Nuevo</button>
      <button *ngIf="newPrescription" style="margin-left:30px" mat-raised-button class="submit-button" type="button"
        (click)="onAddClick()">Guardar</button>
      <button mat-raised-button class="cancel-button" type="button" (click)="closeWithoutSave()">Cerrar</button>
    </div>



    <div fxFlex="10%" fxFlexOrder="2" style="margin-top: 20px">
      <mat-error>{{error}}</mat-error>
    </div>

  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <!--<div class="main-container" style="margin-top:30px;margin-left:30px;text-align: center">
  <div class="centered-horizontally-container" fxFlex="50%" fxFlexOrder="1" style="text-align: center">
    <button [mat-dialog-close]="prescription" type="button" mat-raised-button class="submit-button" >ENVIAR</button>
    <button mat-raised-button class="cancel-button" type="button"  (click)="closeWithoutSave()">CANCELAR</button>
  </div>-->

  <!--
  <div style="width:100%; margin-bottom: 10px; margin-top:30px; text-align: center">							
    <div class="centered-horizontally-container" style="text-align: center">	
      <button [mat-dialog-close]="prescription" style="text-align: center" type="button" mat-raised-button class="submit-button" >ENVIAR</button>
      <button mat-raised-button style="text-align: center" type="button" class="cancel-button" (click)="closeWithoutSave()">CANCELAR</button>
    </div>
  </div>-->

</mat-dialog-actions>