import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { VideocallService } from '../videocall.service';
import { Videocall } from '../domain/Videocall';
import { AuditDTO } from '../domain/AuditDTO';
import { StringUtil } from '../util/StringUtil';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { AuditService } from '../audit.service';
import { GeneralResponse } from '../domain/GeneralResponse';
import { ModalService } from '../home/modal.service';
import { Session } from '../util/Session';
var Video = require('twilio-video');
const { connect, createLocalTracks } = require('twilio-video');

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css']
})
export class AuditComponent implements OnInit {

  private videocalls: Videocall[];
  private videocall: Videocall;
  auditing: boolean = false;
  private newAuditDTO: AuditDTO;
  loading: boolean = false;
  error: boolean = false;
  auditForm: FormGroup;
  homePath: string;

  constructor(private videocallService: VideocallService, private auditService: AuditService, private location: Location, public modalService: ModalService)  { }

  ngOnInit() {
    this.homePath = Session.getHomePath();
    this.getVideocallsInProgress();
    this.auditForm = new FormGroup({
      comment: new FormControl(null, [Validators.required])
    });
    console.log(`localStorage ngOnInit AUDIT: ${localStorage.getItem("Browser")}`);
  }

  ngAfterViewInit() {
    let loadingElement = document.querySelector('#loading');
    if(loadingElement){
      loadingElement.remove();
    }
  }

  ngOnDestroy() {
    if (this.activeRoom) {
      this.activeRoom.disconnect();
    }
  }

  getVideocallsInProgress() {
    this.error = false;
    this.videocallService.inProgress().subscribe(
      videocalls => {
        this.videocalls = videocalls;
      },
      err => {
        console.log(err);
      }
    );
  }

  startAudit(): void {
    // Audit
    this.auditing = true;
    this.newAuditDTO = new AuditDTO();
    this.newAuditDTO.startDate = new Date();
    this.newAuditDTO.videocallId = this.videocall.id;
    // Start
    this.auditService.startAudit(this.newAuditDTO)
      .subscribe(number =>
        this.newAuditDTO.auditId = number
        );
  }

  endAudit(): void {
    // Audit
    this.newAuditDTO.endDate = new Date();
    // End
    this.auditService.endAudit(this.newAuditDTO)
      .subscribe(generalResponse =>{
          this.openDialog("Auditoria", "Se guardaron correctamente los comentarios", "ACEPTAR");
          this.validateResponse(generalResponse)
        }
        );
  }

  getVideocallToAudit(videocallId: number) {
    if (localStorage.getItem("isWebRTCSupported") == "false") {
      this.openDialog("Doctor en Casa", "Tu navegador no soporta videollamadas.\nNo se puede conectar a la sala.", "ACEPTAR");
    } else {
      this.videocallService.getAuditorToken(videocallId).subscribe(
        videocall => {
          this.videocall = videocall;
          //this.startAudit();
          //console.log('Auditing');
          // Loading
          this.loading = true;
          this.makeCall();
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  onCancelClick(): void {
    if (this.auditorConnected == true) {
      this.activeRoom.disconnect();
      this.auditorConnected = false;
    }
    this.auditing = false;
    this.loading = false;
  }

  onRefreshClick(): void {
    this.getVideocallsInProgress();
  }

  onFinishAuditClick(myForm: NgForm): void { 
    if (this.auditorConnected == true) {
      this.activeRoom.disconnect();
      this.auditorConnected = false;
      console.log('Desconectado');
    }
    this.endAudit();
    // refresh
    this.getVideocallsInProgress();    
  }

  validateResponse(generalResponse: GeneralResponse): void {
    if (generalResponse) {
      this.auditing = false;
    } else {
      console.log('Error saving audit: ');
    }
  }

  // VIDEOCALL
  activeRoom: any;
  localAudioTrack: any;
  localVideoTrack: any;
  localTracks: any;
  attachUserTracks: boolean = false;
  attachDoctorTracks: boolean = false;
  auditorConnected:boolean = false;
  //Constants
  PARTICIPANT_IDENTITY_USER: string = "ROLE_USER";
  PARTICIPANT_IDENTITY_DOCTOR: string = "ROLE_DOCTOR";

  makeCall(): void {
    createLocalTracks({
      audio: false,
      video: false
    }).then(localTracks => {
      this.localTracks = localTracks;
      // Attach the Tracks of the Room's Participants.
      localTracks.forEach((track) => {
        if (track.kind == "audio") {
          this.localAudioTrack = track;
        }
        if (track.kind == "video") {
          this.localVideoTrack = track;
        }
      });

      var connectOptions = {
        name: this.videocall.roomName,
        tracks: localTracks
      };
      //Video.connect(this.videocall.tokenTwilio, {'name': this.videocall.roomName, tracks: []}).then(x => {this.roomJoined(x)},  (error) => {
      Video.connect(this.videocall.tokenTwilio, connectOptions).then(x => { this.roomJoined(x) }, (error) => {
        console.log('Could not connect to Twilio: ' + error.message);
        console.log('Could not connect to Twilio TOKEN: ' + this.videocall.tokenTwilio);
        this.auditing = false;
        this.newAuditDTO = null;
        this.loading = false;
        this.error = true;
      });
    }, error => {
      console.log('Could not create local tracks: ' + error.message);
    })
    ;
  }

  setVideoSizeUser() {
    console.log(`setVideoSizeUser`);
    var videoList = document.getElementsByTagName("video");
    console.log(videoList);
    var video;
    Array.prototype.forEach.call(videoList, a => {
      if (a.parentElement.id == 'user-video') {
        video = a;
      }
    });

    var container = document.getElementById('main-left');
    if (video && container) {
      video.height = container.offsetHeight;
      video.width = container.offsetWidth;
      video.setAttribute('style', 'object-fit:fill');
    }
  }
 
  setVideoSizeDoctor() {
    console.log(`setVideoSizeDoctor`);
    var videoList = document.getElementsByTagName("video");
    var video;
    Array.prototype.forEach.call(videoList, a => {
      if (a.parentElement.id == 'doctor-video')
        video = a;
    });
    var container = document.getElementById('main-right');
    if (video && container) {
      video.height = container.offsetHeight;
      video.width = container.offsetWidth;
      video.setAttribute('style', 'object-fit:fill');
    }
  }

  // Successfully connected!
  roomJoined(room) {

    this.startAudit();
    this.auditorConnected = true;
    console.log('Auditing');
    this.loading = false;
    this.activeRoom = room;

    console.log(`connected`);

    room.participants.forEach(participant => {
      if (participant.identity == this.PARTICIPANT_IDENTITY_USER) {
        console.log(`USUARIO ROLE: "${participant.identity}" connected`);

        participant.on('trackSubscribed', track => {
          if (track.kind === 'video') {
            var previewContainer = document.getElementById('user-video');
            previewContainer.appendChild(track.attach());
            this.attachUserTracks = true;
            this.setVideoSizeUser();
            console.log(`trackSubscribed user`);
          }
          if (track.kind === 'audio') {
            var previewContainer = document.getElementById('user-video');
            previewContainer.appendChild(track.attach());
            console.log(`trackSubscribed user audio`);
          }
        });

        participant.on('trackUnsubscribed', track => {
          track.detach().forEach(element => element.remove());
          this.attachUserTracks = false;
          console.log(`trackUnsubscribed user`);
        });
      }

      if (participant.identity == this.PARTICIPANT_IDENTITY_DOCTOR) {
        console.log(`USUARIO ROLE: "${participant.identity}" connected`);

        participant.on('trackSubscribed', track => {
          if (track.kind === 'video') {
            var previewContainer = document.getElementById('doctor-video');
            previewContainer.appendChild(track.attach());
            this.attachDoctorTracks = true;
            this.setVideoSizeDoctor();
            console.log(`trackSubscribed doctor`);
          }
          if (track.kind === 'audio') {
            var previewContainer = document.getElementById('doctor-video');
            previewContainer.appendChild(track.attach());
            console.log(`trackSubscribed doctor audio`);
          }
        });

        participant.on('trackUnsubscribed', track => {
          track.detach().forEach(element => element.remove());
          this.attachDoctorTracks = false;
          console.log(`trackUnsubscribed doctor`);
        });
      }
    });

    // Log new Participants as they connect to the Room
    room.once('participantConnected', participant => {
      console.log(`Participant "${participant.identity}" has connected to the Room`);
    });

    // When a Participant leaves the Room, detach its Tracks.
    room.on('participantDisconnected', participant => {
      room.localParticipant.tracks.forEach(publication => {
        const attachedElements = publication.track.detach();
        attachedElements.forEach(element => element.remove());
        console.log(`Participant disconnected: ${participant.identity}`);
      });
      this.activeRoom.disconnect();
    });


    room.on('disconnected', room => {
      // Detach the local media elements
      room.localParticipant.tracks.forEach(publication => {
        const attachedElements = publication.track.detach();
        attachedElements.forEach(element => element.remove());
        //turn off camera
        publication.track.stop();
      });

      // To disconnect from a Room
      room.disconnect();
      this.openDialog("Auditoria", "Se perdió la conexión con la sala ", "ACEPTAR");
      this.activeRoom.disconnect();
      this.auditorConnected = false;
    });
  }

  // UTILS
  toDate(date: number): string {
    return StringUtil.toDate(date);
  }
  // END UTILS

    // DIALOGS
  // Dialog variables
  commonDialogId = 'commonDialogId';
  dialogTitle: String;
  dialogText: String;
  dialogButtonText: String;
  @ViewChild('interviewInput') interviewInput: ElementRef;

  openDialog(title: string, message: string, buttonLabel: string): void {
    this.dialogButtonText = buttonLabel;
    this.dialogText = message;
    this.dialogTitle = title;
    let modal = this.modalService.open(this.commonDialogId);
    //this.detectChanges();
    modal.finalizeClick.subscribe((data) => {
      //this.auditing = false;
      //this.detectChanges();
    });
  }

}
