<!DOCTYPE html>
<html>

<head>
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
    charset="utf-8">
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
  <!-- Include in <head> to load fonts from Google -->
  <link href='https://fonts.googleapis.com/css?family=Lato:100italic' rel='stylesheet' type='text/css'>
  <link href='https://fonts.googleapis.com/css?family=Lato:100' rel='stylesheet' type='text/css'>
  <link href='https://fonts.googleapis.com/css?family=Lato:normal' rel='stylesheet' type='text/css'>
  <link href='https://fonts.googleapis.com/css?family=Lato:300' rel='stylesheet' type='text/css'>

</head>

<body style="height: 100vh">

  <app-menu></app-menu>

  <div class="searchDiv" xLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="0px" fxLayoutGap.xs="0">
    <span fxFlex="10%" fxFlexOrder="1" class="title">Historias clinicas</span>
    <div fxFlex="90%" fxFlexOrder="2" style="display: flex; justify-content: flex-end; align-items: center">
      <form id="searchForm" [formGroup]="searchForm">
        <div class="container" style="margin-left: 30px;">


          <label>Fecha desde</label>
          <mat-form-field class="date" style="margin-left: 15px;" [hideRequiredMarker]="false">
            <input matInput type="date" #from formControlName="from" useValueAsDate required>
          </mat-form-field>

          <label style="margin-left: 15px; margin-right: 15px;">hasta</label>
          <mat-form-field>
            <input matInput type="date" #to formControlName="to" useValueAsDate required>
          </mat-form-field>

          <label style="margin-left: 15px;">Id</label>
          <mat-form-field [hideRequiredMarker]="false">
            <input matInput type="text" #videoCallId>
          </mat-form-field>

          <label style="margin-left: 15px; margin-right: 15px;">DNI</label>
          <mat-form-field>
            <input matInput type="text" #documentNumber formControlName="documentNumber">
          </mat-form-field>

          <label style="margin-left: 15px; margin-right: 15px;"></label>
          <mat-form-field>
            <mat-select #agreementId placeholder="Convenios" (selectionChange)="select($event.value)">
              <mat-option>--Seleccione--</mat-option>
              <mat-option *ngFor="let agreement of agreements" [value]="agreement.id">{{agreement.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <button style="margin-right: 24px; margin-left: 30px" mat-raised-button class="search-button"
            (click)="search(from.value,to.value,videoCallId.value,documentNumber.value)"
            [disabled]="searchForm.invalid || loading">BUSCAR</button>

        </div>
      </form>
    </div>
  </div>

  <div *ngIf="notFound" style="margin-top:70px; text-align:center">
    <span class="title">No se encontraron historias clinicas</span>
  </div>

  <div *ngIf="loading"><img id="loader" src="./assets/images/loading.gif"></div>

  <div *ngIf="showClinicalHistories">
    <section style="margin-left: 30px; margin-right: 30px; margin-top: 30px" class="main">
      <table class="table table-bordered table-striped table">
        <thead>
          <tr class="table-title">
            <th>FECHA</th>
            <th>ID CONSULTA</th>
            <th>SCORE</th>
            <th>DOCTOR</th>
            <th>MOTIVO DEL LLAMADO</th>
            <th>ANAMNESIS</th>
            <th>USUARIO</th>
            <th>DNI</th>
            <th>PACIENTE</th>
          </tr>
        </thead>
        <tbody>
          <ng-container>
            <tr
              *ngFor="let clinicalHistory of clinicalHistories | paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
              <td>{{toDate(clinicalHistory.date)}}</td>
              <td>{{clinicalHistory.videoCallId}}</td>
              <td>{{clinicalHistory.score}}</td>
              <td>{{clinicalHistory.doctor}}</td>
              <td>{{clinicalHistory.callReason}}</td>
              <td>{{clinicalHistory.anamnesis}}</td>
              <td>{{clinicalHistory.userName}}</td>
              <td>{{clinicalHistory.documentNumber}}</td>
              <td>{{clinicalHistory.patient}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </section>
    <pagination-controls class="my-pagination" (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente">
    </pagination-controls>
  </div>

  <div *ngIf="showClinicalHistories" style="text-align:center">

    <button style="margin-right: 24px; margin-left: 30px" mat-raised-button class="download-button"
      (click)="downloadPdf()" [disabled]="searchForm.invalid || loading">DESCARGAR</button>

  </div>

</body>

</html>