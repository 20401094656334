<!DOCTYPE html>
<html>
  <head>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
      charset="utf-8"
    />
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"
    />
    <!-- Include in <head> to load fonts from Google -->
    <link
      href="https://fonts.googleapis.com/css?family=Lato:100italic"
      rel="stylesheet"
      type="text/css"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Lato:100"
      rel="stylesheet"
      type="text/css"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Lato:normal"
      rel="stylesheet"
      type="text/css"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Lato:300"
      rel="stylesheet"
      type="text/css"
    />
  </head>

  <body>
    <app-menu></app-menu>
    <div
      fxLayout="column"
      fxLayoutGap="0px"
      fxLayoutGap.xs="10px"
      style="width: 100%; height: 90vh"
      class="main-container"
    >
      <div fxFlexOrder="1" fxFlex="20%">
        <legend class="title" style="margin-top: 50px">Convenios</legend>
      </div>

      <div
        fxFlexOrder="2"
        fxFlex="70%"
        class="container"
        style="margin-left: 30px; margin-top: 30px"
      >
        <div style="text-align: center">
          <div style="text-align: center">
            <mat-form-field>
              <mat-select
                #agreementId
                placeholder="Convenios"
                required
                (selectionChange)="select($event.value)"
              >
                <mat-option
                  *ngFor="let agreement of agreements"
                  [value]="agreement.id"
                  >{{ agreement.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>

            <button
              class="title"
              type="button"
              [disabled]="loading"
              mat-raised-button
              routerLink="add"
              style="margin-top: 30px; box-shadow: none"
            >
              <i class="material-icons" style="font-size: 32px"> add_box </i>
            </button>

            <div class="website">
              <mat-form-field [hideRequiredMarker]="false">
                <input
                  matInput
                  type="text"
                  [(ngModel)]="this.newAgreement.website"
                  name="website"
                  #website="ngModel"
                  placeholder="Web"
                  id="website"
                  required
                />
                <mat-error *ngIf="f.submitted && website.errors?.required"
                  >Ingresá la web</mat-error
                >
              </mat-form-field>
              <mat-form-field [hideRequiredMarker]="false">
                <input
                  matInput
                  type="color"
                  style="width:50px"
                  placeholder="Color Principal"
                  name="primaryColor"
                  #primaryColor="ngModel"
                  required
                  [(ngModel)]="this.newAgreement.primaryColor"
                  placeholder="Color Principal"
                  required
                />
                <mat-error *ngIf="f.submitted && primaryColor.errors?.required"
                  >Ingresá el color principal</mat-error
                >
              </mat-form-field>
              <mat-form-field [hideRequiredMarker]="false">
                <input
                  matInput
                  type="color"
                  style="width:50px"
                  [(ngModel)]="this.newAgreement.secondaryColor"
                  name="secondaryColor"
                  #secondaryColor="ngModel"
                  placeholder="Color secundario"
                  id="secondaryColor"
                  required
                />
                <mat-error
                  *ngIf="f.submitted && secondaryColor.errors?.required"
                  >Ingresá el color secundario</mat-error
                >
              </mat-form-field>
              <mat-form-field [hideRequiredMarker]="false">
                <input
                  matInput
                  type="color"
                  style="width:50px"
                  [(ngModel)]="this.newAgreement.mainFontColor"
                  name="mainFontColor"
                  #mainFontColor="ngModel"
                  placeholder="Color Principal del texto"
                  id="mainFontColor"
                  required
                />
                <mat-error *ngIf="f.submitted && mainFontColor.errors?.required"
                  >Ingresá el color principal del texto</mat-error
                >
              </mat-form-field>
              <mat-form-field [hideRequiredMarker]="false">
                <input
                  matInput
                  type="color"
                  style="width:50px"
                  [(ngModel)]="this.newAgreement.secondaryFontColor"
                  name="secondaryFontColor"
                  #secondaryFontColor="ngModel"
                  placeholder="Color Secundario del texto"
                  id="secondaryFontColor"
                  required
                />
                <mat-error
                  *ngIf="f.submitted && secondaryFontColor.errors?.required"
                  >Ingresá el color secundario del texto</mat-error
                >
              </mat-form-field>
              <mat-form-field>
                <mat-select
                  #agreementId
                  placeholder="Fuente"
                  [(value)]="currentFont"
                  required
                  (selectionChange)="selectFont($event.value)"
                >
                  <mat-option *ngFor="let font of fonts" [value]="font">{{
                    font
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
              <br />
              <button
                [disabled]="loading"
                mat-raised-button
                class="update-button"
                style="margin-left: 30px"
                (click)="updateWebsite()"
                type="button"
              >
                ACTUALIZAR
              </button>
            </div>
          </div>
          <hr />
          <form
            name="form"
            enctype="multipart/form-data"
            (ngSubmit)="f.form.valid && uploadFile()"
            #f="ngForm"
            novalidate
          >
            <div style="margin-top: 30px; text-align: center">
              <mat-label
                style="margin-left: 2%; margin-top: 30px"
                class="subtitle"
                >Padrón</mat-label
              >
              <br />
              <button
                class="title"
                type="button"
                mat-raised-button
                (click)="fileInput.click()"
                style="margin-left: 5%; margin-top: 30px"
              >
                Seleccionar archivo
              </button>
              <input
                hidden
                (change)="handleFileInput($event.target.files)"
                #fileInput
                type="file"
                id="fileInput"
              />
              <mat-label style="margin-left: 2%; margin-top: 30px">{{
                selectedFile
              }}</mat-label>
            </div>
            <br />
            <div
              style="margin-top: 30px; margin-bottom: 20px; text-align: center"
            >
              <button
                [disabled]="loading"
                mat-raised-button
                class="save-button"
                style="margin-left: 30px"
              >
                IMPORTAR
              </button>
            </div>
          </form>
          <hr />
          <div style="margin-top: 30px; text-align: center">
            <mat-label
              style="margin-left: 2%; margin-top: 30px"
              class="subtitle"
              >Logos</mat-label
            >
            <br />
            <mat-label style="margin-left: 2%; margin-top: 30px"
              >Logo Principal</mat-label
            >
            <button
              class="title"
              type="button"
              mat-raised-button
              (click)="fileMainLogoInput.click()"
              style="margin-left: 5%; margin-top: 30px"
            >
              Seleccionar archivo
            </button>
            <input
              hidden
              (change)="handleFileMainLogoInput($event.target.files)"
              #fileMainLogoInput
              accept="image/*"
              type="file"
              id="fileLogoMain"
            />
            <mat-label style="margin-left: 2%; margin-top: 30px">{{
              selectedMainLogoFile
            }}</mat-label>
            <button
              [disabled]="loading"
              mat-raised-button
              class="save-button"
              (click)="uploadFileMainLogo()"
              style="margin-left: 30px; margin-top: 30px"
            >
              SUBIR
            </button>
          </div>
          <div
            style="margin-top: 30px; text-align: center; margin-bottom: 50px"
          >
            <form
              name="formSecundaryLogo"
              enctype="multipart/form-data"
              (ngSubmit)="
                formSecundaryLogo.form.valid && uploadFileSecundaryLogo()
              "
              #formSecundaryLogo="ngForm"
              novalidate
            >
              <br />
              <mat-label style="margin-left: 2%; margin-top: 30px"
                >Logo Secundario</mat-label
              >
              <button
                class="title"
                type="button"
                mat-raised-button
                (click)="fileSecundaryLogoInput.click()"
                style="margin-left: 5%; margin-top: 30px"
              >
                Seleccionar archivo
              </button>
              <input
                hidden
                (change)="handleFileSecundaryLogoInput($event.target.files)"
                accept="image/*"
                #fileSecundaryLogoInput
                type="file"
                id="fileSecundaryLogoInput"
              />
              <mat-label style="margin-left: 2%; margin-top: 30px">{{
                selectedSecundaryLogoFile
              }}</mat-label>
              <button
                [disabled]="loading"
                mat-raised-button
                class="save-button"
                style="margin-left: 30px; margin-top: 30px; margin-bottom: 50px"
              >
                SUBIR
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <app-modal
      [useTwoButtons]="false"
      [modalTitle]="dialogTitle"
      [buttonTitle]="dialogButtonText"
      [blocking]="false"
      [modalId]="commonDialogId"
    >
      <div [innerHTML]="dialogText"></div>
    </app-modal>
  </body>
</html>
