import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Doctor } from '../domain/Doctor';
import { UserService } from '../user.service';
import { RecordingService } from '../recording.service';
//import { NgForm} from '@angular/forms';
import { RecordingDTO } from '../domain/RecordingDTO';
import { StringUtil } from '../util/StringUtil';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ModalService } from '../home/modal.service';
import { Session } from '../util/Session';

@Component({
  selector: 'app-recording',
  templateUrl: './recording.component.html',
  styleUrls: ['./recording.component.css']
})
export class RecordingComponent implements OnInit {

  doctors: Doctor[];
  recordings: RecordingDTO[];
  showRecordings: boolean = false;
  notFound: boolean = false;
  searchForm: FormGroup;
  loading: boolean = false;
  homePath: string;

  constructor(private userService: UserService, private recordingService: RecordingService, private location: Location, public modalService: ModalService)  { }

  ngOnInit() {
    this.homePath = Session.getHomePath();
    this.getAllDoctors();
    this.searchForm = new FormGroup({
      date: new FormControl(null, [Validators.required])
      //doctorId: new FormControl(null, [Validators.required])
    });
  }

  ngAfterViewInit() {
    let loadingElement = document.querySelector('#loading');
    if(loadingElement){
      loadingElement.remove();
    }
  }

  getAllDoctors() {
    this.userService.getDoctorList().subscribe(
      doctors => {
        this.doctors = doctors;
      },
      err => {
        console.log(err);
      }
    );
  }

  onBackClick(): void {
    this.location.back();
  }

  search(date, doctorId): void {
    this.showRecordings = false;
    this.notFound = false;
    this.loading = true;
    if (doctorId == null || doctorId == undefined) {
      this.recordingService.getRecordingsByDate(date).subscribe(
        recordings => {
          if (recordings == null || recordings == undefined || recordings.length == 0) {
            this.notFound = true;
            this.loading = false;
          } else {
            this.recordings = recordings;
            this.showRecordings = true;
            this.loading = false;
          }
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.recordingService.getRecordings(date, doctorId).subscribe(
        recordings => {
          if (recordings == null || recordings == undefined || recordings.length == 0) {
            this.notFound = true;
            this.loading = false;
          } else {
            this.recordings = recordings;
            this.showRecordings = true;
            this.loading = false;
          }
        },
        err => {
          console.log(err);
        }
      );
    }

  }

  createComposition(roomSid: String) {
  console.log(roomSid);
    this.recordingService.createComposition(roomSid).subscribe(
      generalResponse => {
        if (generalResponse) {
          this.openDialog("Grabaciones", "Se ha iniciado su composición de video. \n\n Cuando este lista recibiras un correo, con el enlace de descarga.", "ACEPTAR")
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  // UTILS
  toDate(date: number): string {
    return StringUtil.toDate(date);
  }
  toMinutes(seconds: number): string {
     if(seconds < 60){
       return seconds + "s";
     } else {
       var minutes = seconds / 60;
       minutes = Number(minutes.toFixed())
       var minutesBySeconds = minutes * 60;
       return String(minutes) + "m "+ String(seconds - minutesBySeconds) + "s";
     }
  }
  // END UTILS


 // DIALOGS
  // Dialog variables
  compositionDialogId = 'compositionDialogId';
  dialogTitle: String;
  dialogText: String;
  dialogButtonText: String;

  openDialog(title: string, message: string, buttonLabel: string): void {
    console.log('dialog');
    this.dialogButtonText = buttonLabel;
    this.dialogText = message;
    this.dialogTitle = title;
    let modal = this.modalService.open(this.compositionDialogId);
    console.log(modal);
    modal.finalizeClick.subscribe((data) => {
      this.getAllDoctors();
    });
  }

}

